@use '../style/colorList';

.mainContainer {
  box-sizing: border-box;
  width: 100%;
  border: 0.5px solid #535353;
  border-radius: 20px;
  padding: 50px;

  .title {
    text-align: center;
    font-size: 20px;
    margin-bottom: 50px;
  }

  .textBoxWrap {
    box-sizing: border-box;
    margin: auto;
    max-width: 500px;
    height: fit-content;

    p {
      color: #383838;
      font-weight: 500;
      font-size: 18px;
      margin-bottom: 20px;

      span {
        position: relative;
        font-size: 18px;
        margin-left: 10px;
        color: #676767;
      }
    }

    .payTotalPrice {
      color: black;
      font-size: 20px;
      font-weight: 700;
    }
  }
}

.btnWrap {
  margin: 20px auto;
  display: flex;
  justify-content: center;
  height: fit-content;
  gap: 10px;
}

.payGoodsName {
  display: flex;
  align-items: center;
  gap: 10px;

  span {
    position: relative;
    display: block;
    width: 75%;
    word-break: break-all;
    line-height: 1.5;
  }
}

.payGoodsName_detail {
  display: flex;
  align-items: flex-start;
  gap: 10px;

  span {
    position: relative;
    display: block;
    width: 80%;
    word-break: break-all;
  }
}

.complete_text {
  font-size: 20px;
  letter-spacing: 5px;
  font-weight: 700;
  color: #676767;
  padding: 10px 0 100px 0;
}
