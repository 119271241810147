.content {
  position: relative;

  .section {
    // background-color: beige;
    padding: 50px;

    .title {
      font-size: 20px;
      font-weight: 700;
    }

    .addBtnBox {
      text-align: right;
      padding: 20px 30px;
      border-bottom: solid 2px black;
      margin-bottom: 40px;
    }

    .item_list_body {
      // background-color: #c32922;

      // 아이템 없는 경우
      .none_box {
        text-align: center;
        padding: 50px;
        .none_icon {
          width: 5rem;
        }
        .non_title {
          font-size: 150%;
        }
      }
    }
  }
}

// 맵 리스트 스타일 별도 분리
.item_list_ul {
  font-size: 18px;
  line-height: 1.5;
}

.categories_li {
  display: flex;
  margin-bottom: 20px;
}

.lineWrap {
  .lineFirst {
    position: relative;
    top: calc(92px / 2);
    background-color: black;
    width: 10vw;
    height: 2px;
  }

  .lineTwo {
    position: relative;

    .row {
      position: relative;
      top: calc(92px / 2);
      background-color: black;
      margin-left: 20px;
      width: calc(10vw - 20px);
      height: 2px;
    }

    .col {
      position: absolute;
      background-color: black;
      left: 20px;
      height: 100px;
      width: 2px;
    }
  }
}
