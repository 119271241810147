.ql-container.ql-snow.ql-disabled {
  border: none;
}

/* 기본 폰트사이즈 설정 */
.ql-editor {
  font-size: 18px;
}

/* 8px */
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value='8px']::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value='8px']::before {
  content: '8px';
}

.ql-size[data-value='8px'] {
  font-size: 8px !important;
}

/* 9px */
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value='9px']::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value='9px']::before {
  content: '9px';
}

.ql-size[data-value='9px'] {
  font-size: 9px !important;
}

/* 10px */
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value='10px']::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value='10px']::before {
  content: '10px';
}

.ql-size[data-value='10px'] {
  font-size: 10px !important;
}

/* 12px */
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value='12px']::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value='12px']::before {
  content: '12px';
}

.ql-size[data-value='12px'] {
  font-size: 12px !important;
}

/* 14px */
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value='14px']::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value='14px']::before {
  content: '14px';
}

.ql-size[data-value='14px'] {
  font-size: 14px !important;
}

/* 16px */
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value='16px']::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value='16px']::before {
  content: '16px';
}

.ql-size[data-value='16px'] {
  font-size: 16px !important;
}

/* 18px */
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value='18px']::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value='18px']::before {
  content: '18px';
}

.ql-size[data-value='18px'] {
  font-size: 18px !important;
}

/* 20px */
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value='20px']::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value='20px']::before {
  content: '20px';
}

.ql-size[data-value='20px'] {
  font-size: 20px !important;
}

/* 24px */
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value='24px']::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value='24px']::before {
  content: '24px';
}

.ql-size[data-value='24px'] {
  font-size: 24px !important;
}

/* 30px */
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value='30px']::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value='30px']::before {
  content: '30px';
}

.ql-size[data-value='30px'] {
  font-size: 30px !important;
}

/* 36px */
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value='36px']::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value='36px']::before {
  content: '36px';
}

.ql-size[data-value='36px'] {
  font-size: 36px !important;
}

/* 48px */
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value='48px']::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value='48px']::before {
  content: '48px';
}

.ql-size[data-value='48px'] {
  font-size: 48px !important;
}

/* 60px */
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value='60px']::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value='60px']::before {
  content: '60px';
}

.ql-size[data-value='60px'] {
  font-size: 60px !important;
}

/* 72px */
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value='72px']::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value='72px']::before {
  content: '72px';
}

.ql-size[data-value='72px'] {
  font-size: 72px !important;
}
