@use '../style/colorList';
@use '../style/breakPoint';

.required {
  color: red;
}
.plus_icon {
  cursor: pointer;
}

.option_box {
  position: relative;
  width: 100%;
  height: fit-content;
  margin: 0;

  .option_header {
    display: flex;
    justify-content: left;
    align-items: center;
    margin-bottom: 12px;
    gap: 10px;
  }
}

.option_container {
  position: relative;
  width: 100%;
  height: fit-content;
  margin: auto;

  .option_input_title_box {
    display: flex;
    width: 100%;
    justify-content: space-evenly;
    align-items: center;
    gap: 20px;
    margin-top: 30px;
    margin-bottom: 10px;

    p {
      flex: 1;
      text-align: center;
    }

    & > :first-child {
      border-right: 1.5px solid colorList.$lightGray;
    }

    & > :nth-child(2) {
      border-right: 1.5px solid colorList.$lightGray;
    }

    // additational style for option price field
    & > :nth-child(3) {
      border-right: 1.5px solid colorList.$lightGray;
    }
  }

  .option_input_box {
    display: flex;
    width: 100%;
    justify-content: space-evenly;
    align-items: center;
    gap: 20px;
    margin: 10px 0;

    input {
      flex: 1;
      &::placeholder {
        font-size: 16px;
        @media screen and (max-width: breakPoint.$extraSmallScreen) {
          font-size: 11px;
        }
      }
    }

    .plus_minus_box {
      display: flex;
      gap: 10px;
      padding: 10px;
      flex: 1;
    }
  }
}
