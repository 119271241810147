.lineDeco {
  height: 1px;
  background-color: red;
  width: 18px;
}
.title {
  color: #222222;
  padding: 30px 0px;
  font-size: 30px;
  font-weight: 500;
  font-family: 'Pretendard-medium';
}
.menu {
  padding-top: 20px;
  display: flex;
  justify-content: space-around;
  position: relative;
  width: 100%;
  box-sizing: border-box;
  height: fit-content;
  // background-color: beige;
  .menuSelect {
    text-align: center;
    color: gray;
    box-sizing: border-box;
    width: 100%;
    font-size: 24px;
    padding: 20px;
    border-radius: 10px 10px 0px 0px;
    cursor: pointer;
    &:hover {
      background-color: rgba(187, 187, 187, 0.3);
    }
  }
  .selected {
    color: #222222;
    border-width: 2px 2px 0px 2px;
    border-color: black;
    border-style: solid;
    pointer-events: none;
  }
  .unSelected {
    border-width: 0px 0px 2px 0px;
    border-color: black;
    border-style: solid;
  }
}
