@use '../style/colorList';

.login_container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70vh;

  .login_box {
    width: 300px;
    padding: 40px;
    position: relative;
    background: #fff;
    box-shadow: 0 15px 25px 0 rgba(0, 0, 0, 0.1);
    border-radius: 10px;

    .login_h1 {
      margin: 0 0 20px;
      text-align: center;
      font-size: 20px;
      font-weight: 700;
    }

    .extra_links {
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: rgb(60, 60, 60);
      font-size: 14px;
      margin-bottom: 30px;

      .find_box {
        flex: 2;
        display: flex;
        justify-content: flex-end;

        span {
          cursor: pointer;
        }

        & > :first-child {
          &::after {
            content: '|';
            margin: 0px 10px;
            font-size: 10px;
            color: rgb(173, 173, 173);
          }
        }
      }
    }

    .btn_box {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }
  }
}
