.admin_container {
  width: 100vw;
  margin: 0 auto;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

  .admin_header {
    background-color: #2c3e50;
    color: white;
    padding: 10px 0;
    text-align: center;
    z-index: 5;
  }

  .nav_main_wrap {
    display: flex;
    .admin_nav {
      flex: 1;
      position: relative;
      display: inline-block;
      height: 70vh;
      max-height: 70vh;
      padding: 50px 30px;
      //   background-color: red;
      border: solid 0.5px rgba(0, 0, 0, 0.5);
      border-radius: 0px 0px 30px 30px;
      z-index: 3;
      ul {
        list-style: none;
        li {
          margin-bottom: 15px;
          div {
            word-break: break-all;
            line-height: 1.5;
            color: #2c3e50;
            text-decoration: none;
            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
    }

    .admin_main {
      flex: 9;
      width: 100vw;
    }
  }
}

.category_add_btn {
  margin-left: 5px;
  margin-right: 10px;
  margin-bottom: 20px;
  background: #ff7200;
  border: none;
  padding: 10px;
  cursor: pointer;
  font-size: 18px;
  color: white;
  border-radius: 5px;
}
