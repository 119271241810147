@use '../style/colorList';

.authContainer {
  position: relative;
  display: flex;
  width: 100%;
  height: fit-content;
  justify-content: space-between;
  align-items: center;
  gap: 5px;

  .authBoxWrap {
    flex: 5;
    position: relative;
    width: 100%;
    height: fit-content;

    .countDown {
      position: absolute;
      top: 70px;
      right: 10px;
      color: colorList.$darkBlue;
      z-index: 3;
    }
  }

  .resend_btn {
    position: relative;
    top: 16px;
  }
}

.auth_subtitle {
  font-size: 15px;
  margin-bottom: 20px;
  color: colorList.$main;
}
