.not-found-body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    background-color: #f6f6f6;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}
.not-found-container {
    flex: 1;
    width: 80%;
    max-width: 1080px;
    margin: auto;
    padding: 20%;
    text-align: center;
    background-color: #fff;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.not-found-h2 {
    font-size: 35px;
    color: #333;
    margin-bottom: 50px;
}

.not-found-p {
    font-size: 20px;
    color: #666;
    margin-bottom: 50px;
}

.cta-buttons button {
    background-color: #2E4995;
    color: #fff;
    padding: 15px 30px;
    font-size: x-large;
    border: none;
    cursor: pointer;
    margin: 10px;
    border-radius: 10px;
    transition: background-color 0.3s;
}

.cta-buttons button:hover {
    background-color: #0056b3;
}