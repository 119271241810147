@use '../style/breakPoint';
@use '../style/colorList';
@use '../style/line';

.top_btn_box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;

  .back_btn_wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;

    .back_btn_title {
      font-size: 15px;
    }
  }

  .share_icon {
    width: 48px;
    height: 48px;
    box-sizing: border-box;
    border: 1px solid #bdbdbd;
    border-radius: 100%;
    background: url('../img/icon/icon-share.svg') no-repeat center;
    background-size: 24px;
    margin-left: auto;
    cursor: pointer;
    @media screen and (max-width: breakPoint.$mediumSmallScreen) {
      width: 30px;
      height: 30px;
      background-size: 20px;
    }
  }
}

.detail_head {
  box-sizing: border-box;
  padding-bottom: 80px;
  display: flex;
  gap: 40px;
  justify-content: space-between;
  align-items: center;
  @media screen and (max-width: breakPoint.$smallScreen) {
    display: block;
  }

  .thumbnail_box {
    box-sizing: border-box;
    flex: 1;
    @media screen and (max-width: breakPoint.$full) {
      flex: 1.5;
    }

    .thumbnail_list {
      width: 100%;
      margin-top: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 24px;

      .thumbnail_item {
        width: 94px;
        height: 94px;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        box-sizing: border-box;
        border: 1px solid #ddd;
        border-radius: 10px;
        cursor: pointer;

        @media screen and (max-width: breakPoint.$largeScreen) {
          width: 70px;
          height: 70px;
        }
      }
    }
  }

  .detail_box {
    flex: 1;
    box-sizing: border-box;
    @media screen and (max-width: breakPoint.$smallScreen) {
      max-width: 450px;
      margin: auto;
      margin-top: 20px;
    }

    .title_name {
      flex: 12;
      font-size: 24px;
      word-break: break-all;
      line-height: 1.5;
      font-family: 'Pretendard-bold';
      @media screen and (max-width: breakPoint.$mediumSmallScreen) {
        font-size: 20px;
        line-height: 1.3;
      }
    }

    .bot {
      margin-top: 48px;

      @media screen and (max-width: breakPoint.$largeScreen) {
        margin-top: 15px;
      }

      .info {
        box-sizing: border-box;
        width: 100%;
        padding: 24px 0;

        .info_item {
          margin-top: 20px;
          display: flex;
          align-items: center;
          gap: 20px;
          @media screen and (max-width: breakPoint.$mediumSmallScreen) {
            margin-top: 15px;
          }

          &:first-child {
            margin-top: 0;
          }
          .title {
            width: 100px;
            font-size: 16px;
            line-height: 1.5;
            font-family: 'Pretendard-semibold';
            @media screen and (max-width: breakPoint.$mediumSmallScreen) {
              font-size: 14px;
              line-height: 1.3;
            }
          }
          .con {
            justify-items: right;
            font-size: 16px;
            line-height: 1.5;
            font-family: 'Pretendard-regular';
            @media screen and (max-width: breakPoint.$mediumSmallScreen) {
              font-size: 14px;
              line-height: 1.3;
            }
          }
        }
      }

      .buy_box {
        position: relative;
        width: 100%;
        box-sizing: border-box;
        padding: 24px;
        background-color: #f8f8f8;
        margin-top: 80px;
        margin-bottom: 60px;
        @media screen and (max-width: breakPoint.$mediumSmallScreen) {
          margin-top: 20px;
          padding: 15px 10px;
        }
        .con {
          display: flex;
          justify-content: space-between;
          align-items: center;
          @media screen and (max-width: breakPoint.$mediumSmallScreen) {
            display: block;
          }

          .num {
            display: flex;
            align-items: center;
            box-sizing: border-box;
            border: 1px solid #ddd;
            @media screen and (max-width: breakPoint.$mediumSmallScreen) {
              width: fit-content;
              margin: auto;
            }

            button {
              width: 32px;
              height: 32px;
              background-color: #fff;
              background-size: 24px;
              background-repeat: no-repeat;
              background-position: center;
              @media screen and (max-width: breakPoint.$largeScreen) {
                width: 28px;
                height: 28px;
                background-size: 20px;
              }
            }
            .num_minus {
              background-image: url('../img/icon/icon-remove.svg');
            }
            .num_input {
              border: 1px solid #ddd;
              border-top: none;
              border-bottom: none;
              box-sizing: border-box;
              width: 80px;
              height: 32px;
              text-align: center;
              padding: 0 10px;
              @media screen and (max-width: breakPoint.$largeScreen) {
                width: 50px;
                font-size: 14px;
                padding: 0 5px;
              }
              @media screen and (max-width: breakPoint.$mediumSmallScreen) {
                width: 70px;
                font-size: 15px;
                padding: 0 5px;
              }
            }
            .num_plus {
              background-image: url('../img/icon/icon-add.svg');
            }
          }
          .total {
            @media screen and (max-width: breakPoint.$mediumSmallScreen) {
              text-align: center;
              margin-bottom: 10px;
            }
            .price {
              font-size: 20px;
            }
            .won {
              font-size: 14px;
              margin-left: 4px;
            }
          }
        }
      }

      .total_price {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .title {
          font-size: 24px;
          .vat {
            font-size: 20px;
            @media screen and (max-width: breakPoint.$largeScreen) {
              margin-left: 13px;
              font-size: 12px;
              display: block;
              margin-top: 5px;
            }
          }
        }
        .price {
          .num {
            font-size: 36px;
            color: #b70000;
          }
          .won {
            font-size: 24px;
            margin-left: 4px;
          }
        }
      }

      .order_method_box {
        display: flex;
        align-items: center;
        gap: 20%;
        @media screen and (max-width: breakPoint.$smallScreen) {
          justify-content: right;
        }
      }

      .btn_list {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 8px;
        margin-top: 24px;
        @media screen and (max-width: breakPoint.$smallScreen) {
          margin-top: 5px;
        }
      }
    }
  }
}

.detail_con {
  position: relative;
  box-sizing: border-box;
  width: 100%;

  .item_head {
    width: 100%;
    margin: 'auto';
    margin-bottom: 40px;
    border-bottom: 1px solid black;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .title {
      font-size: 32px;
      margin-bottom: 15px;
    }
    .add_file_btn {
      font-size: 20px;
      color: #ffffff;
      line-height: 0;
      background-color: #2e4995;
      padding: 20px 40px;
    }
  }

  .detail_content_wrap {
    position: relative;
    min-height: 700px;

    @media screen and (max-width: 1100px) {
      padding-right: 200px;
    }
    @media screen and (max-width: breakPoint.$tabletScreen) {
      padding-right: 0;
    }
  }
}

@include line.bold_line();

.product_image_wrap {
  position: relative;
  width: 80%;
  margin: auto;
}

.option_box {
  gap: 20px;
  width: 100%;
  margin-bottom: 15px;
  .title {
    flex: 3;
    font-size: 16px;
    margin-bottom: 5px;
  }
}
