@use '../style/colorList';
@use '../style/breakPoint';
@use '../style/pointer';

.content {
  position: relative;

  .section {
    // background-color: beige;
    padding: 50px;

    .title_box {
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;
      div {
        .title {
          font-size: 20px;
          font-weight: 700;
        }
      }
    }

    .item_list_body {
      // background-color: #c32922;
      .item_list_head {
        // background-color: #2e4995;
        display: flex;
        gap: 15px;
        border-top: solid 2px black;
        border-bottom: solid 1px rgba(0, 0, 0, 0.2);
        padding: 20px 0px;
        justify-content: space-around;
        text-align: center;
        .title_info {
          flex: 5;
        }
        .title_date {
          flex: 1.5;
        }
        .title_price {
          flex: 1;
        }
        .title_status {
          flex: 1;
        }
      }

      // 아이템 없는 경우
      .none_box {
        text-align: center;
        padding: 50px;
        .none_icon {
          width: 5rem;
        }
        .non_title {
          font-size: 150%;
        }
      }

      // 아이템 있을떄
      .breakdown_body {
        .breakdown_item {
          display: flex;
          gap: 15px;
          align-items: center;
          padding: 20px 0px;
          border-bottom: solid 1px rgba(0, 0, 0, 0.2);
          cursor: pointer;
          @include pointer.hover();

          .con_info {
            display: flex;
            align-items: center;
            flex: 5;
            gap: 10px;
            .thumbnail {
              background-position: center;
              background-repeat: no-repeat;
              background-size: contain;
              width: 140px;
              height: 100px;
              border-radius: 10px;
              border: 0.5px solid rgb(153, 153, 153);
            }
            .info_box {
              .p_company {
                font-size: 16px;
                margin-bottom: 10px;
              }
              .p_name {
                font-size: 16px;
                font-weight: 700;
              }
            }
          }
          .con_date {
            flex: 1.5;
            text-align: center;
            .p_order_number {
              margin-bottom: 10px;
            }
            .p_order_time {
              color: gray;
            }
          }
          .con_price {
            flex: 1;
            text-align: center;
            .p_price {
              margin-bottom: 10px;
            }
            .p_num {
              color: gray;
            }
          }
          .con_status {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            flex: 1;
            text-align: center;

            .confirm_btn {
              color: #2e4995;
              border: solid 1px #2e4995;
              border-radius: 10px;
              padding: 10px 30px;
              cursor: pointer;
              margin-bottom: 10px;
            }

            .cancel_btn {
              color: #c32922;
              border: solid 1px #c32922;
              border-radius: 10px;
              padding: 10px 30px;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}
