@use '../style/colorList';

.login_container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 70vh;

  .login_box {
    width: 300px;
    padding: 40px;
    position: relative;
    background: #fff;
    box-shadow: 0 15px 25px 0 rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    margin: 50px 0px;

    .login_h1 {
      margin: 0 0 20px;
      text-align: center;
      font-size: 20px;
      font-weight: 700;
    }

    .authContainer {
      position: relative;
      display: flex;
      width: 100%;
      height: fit-content;
      justify-content: space-between;
      align-items: center;

      gap: 5px;

      .authBoxWrap {
        flex: 8;
        position: relative;
        width: 100%;
        height: fit-content;

        .countDown {
          position: absolute;
          top: 70px;
          right: 10px;
          color: colorList.$darkBlue;
          z-index: 3;
        }
      }

      .resend_btn {
        position: relative;
        top: 16px;
      }
    }

    .auth_subtitle {
      font-size: 15px;
      margin-bottom: 20px;
      color: colorList.$main;
    }

    .btn_box {
      display: flex;
      flex-direction: column;
      gap: 5px;
    }
  }

  .success_box {
    width: 300px;
    padding: 40px;
    position: relative;
    background: #fff;
    box-shadow: 0 15px 25px 0 rgba(0, 0, 0, 0.1);
    border-radius: 10px;

    .success_h1 {
      font-size: 18px;
      margin-bottom: 10px;
    }
    .success_h1_line {
      font-size: 18px;
      margin-bottom: 10px;
      line-height: 1.5;
    }
    .success_h3 {
      font-size: 15px;
      color: colorList.$darkGray;
    }

    .information_box {
      padding: 80px 40px;

      .id_info {
        font-size: 20px;
        margin-bottom: 5px;
        span {
          color: colorList.$darkGray;
        }
      }
      .date_info {
        font-size: 12px;
        color: colorList.$darkGray;
        margin-bottom: 12px;
      }
      .pw_info {
        font-size: 15px;
        color: colorList.$main;
      }
    }

    .btn_box {
      position: relative;
      width: 100%;
      height: fit-content;
      display: flex;
      flex-direction: column;
      gap: 10px;
    }
  }
}
