.headerBox {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: fit-content;
  box-sizing: border-box;
  border-top: 2px solid black;
  border-bottom: 1px solid #e0e0e0;
  padding: 30px;

  .leftSpace {
    .title {
      display: inline-block;
      font-size: 24px;
      margin-right: 20px;
      width: fit-content;
      height: fit-content;
      word-break: break-all;
    }
    .boardType {
      display: inline-block;
      font-size: 12px;
      color: rgb(129, 129, 129);
      &::before {
        content: '|';
        margin-right: 5px;
        color: rgb(129, 129, 129);
      }
    }
  }

  .rightSpace {
    position: relative;
    width: 200px;
    .date {
      display: block;
      text-align: right;
      // background-color: red;
      color: rgb(129, 129, 129);
      font-size: 14px;
      margin-bottom: 10px;
    }
    .writer {
      text-align: right;
      font-size: 14px;
      color: rgb(129, 129, 129);
      span {
        font-size: 10px;
      }
    }
    .editBtn {
      margin-top: 10px;
      text-align: right;
    }
  }
}

.viewCount {
  padding: 10px 30px;
  text-align: right;
  font-size: 14px;
  color: rgb(129, 129, 129);
}

.mentionContainer {
  margin: 0 auto;
  box-sizing: border-box;
  max-width: 700px;
  height: fit-content;

  .metionTitle {
    font-size: 18px;
    padding: 10px 0px;
    border-bottom: 1px solid #858585;
  }

  .mentionContentWrap {
    box-sizing: border-box;
    padding: 20px;
    .mentionContentBox {
      box-sizing: border-box;
      border-bottom: 1px solid #bcbcbc;
      margin-top: 30px;
      &:first-child {
        margin-top: 10px;
      }
      .mentionWriter {
        display: inline-block;
        width: fit-content;
        height: fit-content;
        border-bottom: 1px dashed #9d9d9d;
        padding-bottom: 5px;
        margin-right: 10px;
        font-weight: 600;
        letter-spacing: 1px;
      }
      .mentionTime {
        display: inline-block;
        width: fit-content;
        height: fit-content;
        font-size: 12px;
        color: rgb(129, 129, 129);
      }
      .mentionContent {
        padding: 15px 10px;
        line-height: 1.5;
        word-break: break-all;
        white-space: pre-wrap;
      }

      .replayBtnBox {
        display: flex;
        justify-content: right;
        gap: 10px;
        // text-align: right;
        padding: 10px;
      }
    }
  }
}
.mentionOnSendBox {
  box-sizing: border-box;
  position: relative;
  margin: 0 auto;
  max-width: 700px;
  margin-top: 50px;
  margin-bottom: 50px;

  .userName {
    font-weight: 600;
    letter-spacing: 1px;
    margin-bottom: 10px;
    span {
      font-weight: 200;
      font-size: 14px;
      color: rgb(129, 129, 129);
      margin-right: 10px;
    }
  }
  .btnBox {
    box-sizing: border-box;
    padding: 10px 0px;
    text-align: right;
  }
}
