@use '../style/colorList';
@use '../style/breakPoint';

.footer {
  position: relative;
  width: 100vw;
  min-height: 100px;
  background-color: #f5f5f5;

  .container {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    max-width: 1200px;
    height: fit-content;
    margin: 0px auto;
    padding: 30px 0px;

    .logo_gray {
      position: relative;
      display: block;
    }
    .statement_box {
      position: relative;
      display: flex;
      gap: 30px;
      color: colorList.$dark;
      font-size: 15px;
      @media screen and (max-width: breakPoint.$mediumScreen) {
        font-size: 12px;
      }
      li {
        cursor: pointer;
      }
    }
    .info_detail {
      font-size: 13px;
      color: #666666;
      line-height: 1.5;
      text-align: center;
      @media screen and (max-width: breakPoint.$mediumScreen) {
        font-size: 9px;
      }
    }
    .copyright {
      font-size: 12px;
      color: #a5a5a5;
      line-height: 1.5;
      text-align: center;
      @media screen and (max-width: breakPoint.$mediumScreen) {
        font-size: 10px;
      }
      @media screen and (max-width: breakPoint.$mediumSmallScreen) {
        padding: 0 10px;
      }
      @media screen and (max-width: breakPoint.$phoneFolderScreen) {
        font-size: 9px;
      }
    }
  }
}

.react_footer_info_box {
  position: relative;
  width: 100vw;
  box-sizing: border-box;
  padding: 0 20px;

  .react_statement_box {
    position: relative;
    display: flex;
    justify-content: space-around;
    align-items: center;
    font-size: 12px;
    margin-bottom: 10px;
    @media screen and (max-width: breakPoint.$phoneFolderScreen) {
      font-size: 9px;
    }

    li {
      margin-bottom: 10px;
    }
  }
  .react_info_detail {
    text-align: right;
    padding: 0px 10px;
    font-size: 10px;
    line-height: 1.5;
    color: #666666;

    @media screen and (max-width: breakPoint.$phoneFolderScreen) {
      font-size: 9px;
    }
  }
}
