.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 998;
}

.popup-view {
  background: white;
  padding: 20px;
  width: 500px;
  max-width: 90%;
  height: 250px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.popup-title {
  text-align: center;
  font-size: 1.2rem;
  font-weight: bold;
  line-height: 1.5;
}

.popup-content {
  font-size: 20px;
  text-align: center;
  margin-bottom: 20px;
  white-space: pre-line;
  line-height: 1.5;
}

.popup-confirm-btn,
.popup-cancel-btn {
  background-color: gray;
  border: none;
  width: 100%;
  padding: 10px 20px;
  border-radius: 5px;
  color: white;
  cursor: pointer;
  margin: 0 5px; /* 버튼 사이 간격 */
}

.popup-close-btn {
  background-color: gray;
  border: none;
  width: 100%;
  padding: 10px 20px;
  border-radius: 5px;
  color: white;
  cursor: pointer;
  align-self: center;
}

.popup-two-button-div {
  display: flex;
  justify-content: center;
  align-items: center;
}
