@use '../style/colorList';
@use '../style/breakPoint';

.header {
  position: relative;
  width: 100vw;
  height: fit-content;
  box-sizing: border-box;

  .top_container {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1200px;
    padding: 20px;
    box-sizing: border-box;
    margin: 0 auto;
    background-color: colorList.$white;
    z-index: 15;
    @media screen and (max-width: breakPoint.$extraSmallScreen) {
      padding: 10px 5px 0 5px;
    }

    .main_logo {
      position: relative;
      display: block;
      max-width: 200px;
      cursor: pointer;
      @media screen and (max-width: breakPoint.$mediumSmallScreen) {
        max-width: 150px;
      }
      @media screen and (max-width: breakPoint.$smallScreen) {
        max-width: 120px;
      }
    }

    .right_box {
      position: relative;
      display: flex;
      align-items: center;
      gap: 40px;
      z-index: 15;
      @media screen and (max-width: breakPoint.$mediumSmallScreen) {
        gap: 10px;
      }

      .login_btn_box {
        display: flex;
        flex-grow: 1;
        flex-shrink: 0;
        box-sizing: border-box;
        gap: 20px;

        .cart_btn_wrap {
          position: relative;
          width: 100%;
          height: fit-content;

          .cart_count {
            position: absolute;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            font-size: 14px;
            width: 25px;
            height: 25px;
            top: -10px;
            right: -5px;
            background-color: red;
            color: colorList.$white;
            @media screen and (max-width: breakPoint.$smallScreen) {
              font-size: 12px;
              width: 20px;
              height: 20px;
            }
          }
        }
      }
    }
  }

  .nav_container {
    position: relative;
    width: 100vw;
    height: fit-content;
    box-sizing: border-box;
    background-color: colorList.$main;
    padding: 20px;
    z-index: 10;

    @media screen and (max-width: breakPoint.$extraSmallScreen) {
      padding: 20px 5px;
    }

    .nav_flex_wrap {
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: center;
      max-width: 1200px;
      height: fit-content;
      margin: auto;

      .category_menu_btn {
        position: relative;
        flex: 1;
        cursor: pointer;
      }

      .nav_ul_box {
        position: relative;
        display: flex;
        flex: 9;
        justify-content: space-around;
        align-items: center;
        color: colorList.$white;
        font-size: 18px;
        font-weight: 500;
        @media screen and (max-width: breakPoint.$mediumSmallScreen) {
          font-size: 15px;
        }
        @media screen and (max-width: breakPoint.$extraSmallScreen) {
          font-size: 12px;
        }

        @media screen and (max-width: breakPoint.$phoneFolderScreen) {
          font-size: 9px;
        }

        li {
          cursor: pointer;
          a {
            text-decoration: none;
          }
        }
      }
    }
  }

  .nav_box {
    position: absolute;
    box-sizing: border-box;
    left: 0px;
    width: 100vw;
    transition: transform 0.5s ease-in-out;
    transform-origin: top;
    transform: translateY(-100%);
    background-color: white;
    z-index: 3;

    .nav_1depth,
    .nav_2depth,
    .nav_3depth {
      position: relative;
      display: flex;
      background-color: #fff;
      width: 240px;
      flex-direction: column;
      margin: 32px 20px;
      box-sizing: border-box;
      border-right: 1px solid #eee;
      @media screen and (max-width: breakPoint.$smallScreen) {
        margin: 10px 5px;
      }

      .nav_1depth_item,
      .nav_2depth_item,
      .nav_3depth_item {
        color: #444;
        width: 100%;
        height: 43px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;

        .title {
          font-size: 16px;
          word-break: break-all;
          line-height: 1.2;
          @media screen and (max-width: breakPoint.$smallScreen) {
            font-size: 14px;
          }
          @media screen and (max-width: breakPoint.$phoneFolderScreen) {
            font-size: 12px;
          }
        }
        .hover_action {
          color: #ff7200;
          font-weight: bold;
        }
        .next_icon {
          width: 24px;
          height: 24px;
          background: url('../img/icon/icon-next.svg') no-repeat;
          background-size: cover;
          @media screen and (max-width: breakPoint.$smallScreen) {
            width: 20px;
            height: 20px;
          }
        }
      }
    }

    .nav_1depth {
      z-index: 6;
    }

    .nav_2depth {
      background-color: #fff;
      position: relative;
      transform: translateX(-50%);
      opacity: 0;
      transition: 0.5s;
      z-index: 5;
    }
    .nav_3depth {
      background-color: #fff;
      position: relative;
      transform: translateX(-100%);
      opacity: 0;
      transition: 0.5s;
      z-index: 4;
    }
    .depth_active {
      transform: translateX(0);
      opacity: 1;
    }
  }

  .active {
    transform: translateY(0);
  }
}

.react_header_top_hamburger {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.icon_wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 26px;
  background-color: colorList.$main;
  color: colorList.$white;
  cursor: pointer;
  border-radius: 4px;
  padding: 0px 20px;

  @media screen and (max-width: 680px) {
    font-size: 20px;
  }
  @media screen and (max-width: breakPoint.$smallScreen) {
    font-size: 15px;
  }
}

.react_header_top_dropdown {
  position: absolute;
  top: 90px;
  right: 0;
  width: 141px;
  transition: 0.5s ease-in-out;
  transform: translateY(-100%);
  padding: 30px 20px;
  background-color: colorList.$white;
  color: colorList.$dark;
  border-radius: 0 0 0 10px;
  font-size: 18px;
  box-sizing: border-box;
  border: 0px solid colorList.$main;
  border-width: 0 0 2px 2px;
  box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.3);
  cursor: pointer;
  z-index: 13;
  @media screen and (max-width: 680px) {
    top: 60px;
    width: 130px;
    padding: 30px 20px;
    font-size: 15px;
  }
  @media screen and (max-width: breakPoint.$smallScreen) {
    top: 52px;
    width: 120px;
    padding: 30px 20px;
    font-size: 15px;
  }

  @media screen and (max-width: breakPoint.$extraSmallScreen) {
    top: 42px;
    width: 105px;
    padding: 30px 20px;
    font-size: 13px;
  }

  & > :not(:last-child) {
    margin-bottom: 20px;
    @media screen and (max-width: 680px) {
      margin-bottom: 15px;
    }
  }

  li {
    color: colorList.$dark;
    transition: 0.1s ease-in-out;
    &:hover {
      color: colorList.$main;
      text-decoration: underline;
      text-underline-offset: 5px;
    }
  }
}

.dropdown_active {
  transform: translateY(0);
}

.react_search_box_container {
  position: relative;
  width: 100vw;
  box-sizing: border-box;
  background-color: white;
  z-index: 10;
}
