.join {
  width: 600px;
  margin: 0 auto;
}
.join .terms-box {
  width: 100%;
}
.join .terms-box .terms-item {
  width: 100%;
  margin-top: 32px;
  border: 1px solid #e0e0e0;
  border-radius: 16px;
}
.join .terms-box .terms-item:first-child {
  margin-top: 0;
}
.join .terms-box .terms-item .terms-head {
  width: 100%;
  border-bottom: 1px solid #e0e0e0;
}
.join .terms-box .terms-item .terms-body {
  width: 100%;
  height: 200px;
  padding: 20px;
  word-break: break-all;
  box-sizing: border-box;
  overflow-y: auto;
}
