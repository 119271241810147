@use '../style/colorList';

@mixin hover(
  $color: colorList.$lightGray,
  $transitionDuration: 0.3s,
  $transitionEase: ease,
  $textUnderline: none,
  $textColor: inherit,
  $textUnderlineOffset: 5px,
  $opacity: 1
) {
  &:hover {
    background-color: $color;
    transition: $transitionDuration $transitionEase;
    text-decoration: $textUnderline;
    text-underline-offset: $textUnderlineOffset;
    color: $textColor;
    opacity: $opacity;
  }
}

@mixin active(
  $color: inherit,
  $transitionDuration: 0.1s,
  $transitionEase: ease,
  $textUnderline: none,
  $textColor: inherit,
  $textUnderlineOffset: 5px,
  $opacity: 0.5
) {
  &:active {
    background-color: $color;
    transition: $transitionDuration $transitionEase;
    text-decoration: $textUnderline;
    text-underline-offset: $textUnderlineOffset;
    color: $textColor;
    opacity: $opacity;
  }
}
