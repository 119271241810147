@font-face {
    font-family: 'Pretendard-black';
    font-weight: 900;
    font-display: swap;
    src: local('./Pretendard Black'),
    url('./Pretendard-Black.woff2') format('woff2'),
    url('Pretendard-Black.woff') format('woff');
}
@font-face {
    font-family: 'Pretendard-extrabold';
    font-weight: 800;
    font-display: swap;
    src: local('./Pretendard ExtraBold'),
    url('./Pretendard-ExtraBold.woff2') format('woff2'),
    url('Pretendard-ExtraBold.woff') format('woff');
}
@font-face {
    font-family: 'Pretendard-bold';
    font-weight: 700;
    font-display: swap;
    src: local('./Pretendard Bold'),
    url('./Pretendard-Bold.woff2') format('woff2'),
    url('Pretendard-Bold.woff') format('woff');
}
@font-face {
    font-family: 'Pretendard-semibold';
    font-weight: 600;
    font-display: swap;
    src: local('./Pretendard SemiBold'),
    url('./Pretendard-SemiBold.woff2') format('woff2'),
    url('Pretendard-SemiBold.woff') format('woff');
}
@font-face {
    font-family: 'Pretendard-medium';
    font-weight: 500;
    font-display: swap;
    src: local('./Pretendard Medium'),
    url('./Pretendard-Medium.woff2') format('woff2'),
    url('Pretendard-Medium.woff') format('woff');
}
@font-face {
    font-family: 'Pretendard-regular';
    font-weight: 400;
    font-display: swap;
    src: local('./Pretendard Regular'),
    url('./Pretendard-Regular.woff2') format('woff2'),
    url('Pretendard-Regular.woff') format('woff');
}
@font-face {
    font-family: 'Pretendard-light';
    font-weight: 300;
    font-display: swap;
    src: local('./Pretendard Light'),
    url('./Pretendard-Light.woff2') format('woff2'),
    url('Pretendard-Light.woff') format('woff');
}
@font-face {
    font-family: 'Pretendard-extralight';
    font-weight: 200;
    font-display: swap;
    src: local('./Pretendard ExtraLight'),
    url('./Pretendard-ExtraLight.woff2') format('woff2'),
    url('Pretendard-ExtraLight.woff') format('woff');
}
@font-face {
    font-family: 'Pretendard-thin';
    font-weight: 100;
    font-display: swap;
    src: local('./Pretendard Thin'),
    url('./Pretendard-Thin.woff2') format('woff2'),
    url('Pretendard-Thin.woff') format('woff');
}