@media screen and (min-width: 1280px) {
}
@media screen and (max-width: 1279px) {
}
@media screen and (max-width: 767px) {
}

/* 컨테이너 */
.container {
  margin: 0 auto;
}
@media screen and (min-width: 1280px) {
  .container {
    width: 1280px;
  }
}
@media screen and (max-width: 1279px) {
  .container {
    width: 100%;
    box-sizing: border-box;
    padding: 0 24px;
  }
}
@media screen and (max-width: 767px) {
  .container {
    padding: 0 20px;
  }
}

/* 서치박스 */
.search {
  width: 500px;
  height: 42px;
  position: relative;
}
.search input {
  width: 100%;
  height: 100%;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 0 40px 0 8px;
  box-sizing: border-box;
}
.search .search-btn {
  width: 24px;
  height: 24px;
  background: url('/src/assets/img/icon/icon-search.svg') no-repeat;
  background-size: cover;
  position: absolute;
  top: 50%;
  right: 8px;
  transform: translate(0, -50%);
}
@media screen and (max-width: 1279px) {
  .search {
    width: 400px;
  }
}
@media screen and (max-width: 767px) {
  .search {
    width: 100%;
    max-width: 100%;
  }
}

/* 메인 배너 */
.banner {
  width: 100%;
  height: 500px;
  position: relative;
}
.banner .custom-swiper {
  width: 100%;
  height: 100%;
}
/*.banner .banner-list{width: 100%;height: 100%;}*/
.banner .banner-item01 {
  /* background-image: url('/src/assets/img/index/main_01.jpeg'); */
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}
.banner .banner-item02 {
  /* background-image: url('/src/assets/img/index/main_02.jpeg'); */
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

/* 컨텐츠 간격 */
.section {
  padding-top: 80px;
  padding-bottom: 80px;
}
@media screen and (max-width: 1279px) {
  .section {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}
@media screen and (max-width: 767px) {
  .section {
    padding-top: 52px;
    padding-bottom: 52px;
  }
}

/* 가이드 */
.guide {
  width: 100%;
  height: 100px;
  display: flex;
  flex-direction: column;
  background-color: #df5b4b;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.guide .title {
  font-size: 24px;
  font-family: 'Pretendard-bold';
  color: #fff;
}
.guide .title-eng {
  font-size: 14px;
  font-family: 'Pretendard-light';
  color: #fff;
  margin-top: 16px;
}

/* 게시판 */
.board-wrap {
  margin-top: 80px;
  display: flex;
  align-items: flex-start;
  gap: 48px;
}
.board-wrap .board-section {
  width: calc(50% - 24px);
}
.board-wrap .board-section .board-header {
  width: 100%;
  height: 48px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  border-bottom: 1px solid #bdbdbd;
}
.board-wrap .board-section .board-header .title {
  font-size: 18px;
}
.board-wrap .board-section .board-header .more-btn {
  font-size: 14px;
  display: flex;
  align-items: center;
  cursor: pointer;
  color: #bdbdbd;
  margin-left: auto;
}
.board-wrap .board-section .board-header .more-btn .icon {
  background: url('/src/assets/img/icon/icon-more.svg') no-repeat;
  background-size: cover;
  width: 16px;
  height: 16px;
  margin-right: 4px;
}

.board-wrap .board-section .board-body {
  width: 100%;
  margin-top: 8px;
}
.board-wrap .board-section .board-body .board-item {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 8px 0;
  cursor: pointer;
}
.board-wrap .board-section .board-body .board-item .title {
  font-size: 16px;
  line-height: 24px;
  width: calc(100% - 100px);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  word-break: break-all;
}
.board-wrap .board-section .board-body .board-item .date {
  font-size: 14px;
  font-family: 'Pretendard-regular';
  width: 92px;
  margin-left: auto;
  text-align: right;
}
.board-wrap .board-section .board-body .board-item:hover .title {
  text-decoration: underline;
}
@media screen and (max-width: 1279px) {
  .board-wrap {
    margin-top: 60px;
  }
  .board-wrap .board-section .board-header .title {
    font-size: 16px;
  }
}
@media screen and (max-width: 767px) {
  .board-wrap {
    margin-top: 52px;
    flex-wrap: wrap;
    gap: 32px;
  }
  .board-wrap .board-section {
    width: 100%;
  }
}
