@use '../style/colorList';

@mixin bold_line() {
  .bold_line {
    position: relative;
    width: 100vw;
    background-color: colorList.$lightGray;
    height: 6px;
  }
}
