@charset "utf-8";

/* 크로스브라우징을 위한 CSS 초기화 */
* {
  margin: 0;
  padding: 0;
}
body {
  font-size: 12px;
  font-family: 'Noto Sans KR', sans-serif;
} /* font-family:'Nanum Gothic', sans-serif; */
a {
  outline: 0px;
}
a:link {
  color: inherit;
  text-decoration: none;
}
a:hover {
  color: inherit;
  text-decoration: none;
}
a:visited {
  color: inherit;
  text-decoration: none;
}
caption,
legend {
  font-size: 0px;
  width: 0px;
  height: 0px;
  overflow: hidden;
  position: absolute;
  visibility: hidden;
}
img {
  border: 0px;
}
fieldset,
img {
  border: 0;
  vertical-align: top;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
  border: 0;
}
input,
textarea,
select {
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
}
input[type='password'] {
  ime-mode: disabled;
}
ul,
ol,
dl {
  list-style: none;
  margin: 0;
}
textarea {
  overflow: auto;
}
button,
label {
  cursor: pointer;
}
hr {
  display: none;
  clear: both;
}

/* 관리자 에디터에서 ol, ul 붙혔을경우 읽기페이지에서 나오기 */
.readEdit ul {
  list-style: inside;
  padding-left: 15px;
}
.readEdit ol {
  list-style: decimal;
  padding-left: 15px;
}

/* 로딩 */
.bg_wrap {
  position: fixed;
  z-index: 10000;
  background-color: rgba(0, 0, 0, 0.7);
  width: 100%;
  height: 100vh;
}
.bg_wrap .ring {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 150px;
  height: 150px;
  background: transparent;
  border: 3px solid #3c3c3c;
  border-radius: 50%;
  text-align: center;
  line-height: 150px;
  font-size: 20px;
  color: #fff000;
  letter-spacing: 4px;
  text-transform: uppercase;
  text-shadow: 0 0 10px #fff000;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
}
.bg_wrap .ring:before {
  content: '';
  position: absolute;
  top: -3px;
  left: -3px;
  width: 100%;
  height: 100%;
  border: 3px solid transparent;
  border-top: 3px solid #fff000;
  border-right: 3px solid #fff000;
  border-radius: 50%;
  animation: animateCircle 2s linear infinite;
}
.bg_wrap span {
  display: block;
  position: absolute;
  top: calc(50% - 2px);
  left: 50%;
  width: 50%;
  height: 4px;
  background: transparent;
  transform-origin: left;
  animation: animate 2s linear infinite;
}
.bg_wrap span:before {
  content: '';
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #fff000;
  top: -5px;
  right: -5px;
  box-shadow: 0 0 20px #fff000;
}

@keyframes animateCircle {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes animate {
  0% {
    transform: rotate(45deg);
  }
  100% {
    transform: rotate(405deg);
  }
}

/* 퀵메뉴 */
@charset "utf-8";
.quick {
  display: none;
}

@media all and (max-width: 1000px) {
  .quick_top_btn {
    display: none;
  }
}

/* 공통 */
.sub_tab_btn {
  margin-bottom: 35px;
  overflow-x: auto;
}

.sub_tab_btn ul {
  font-size: 0;
  letter-spacing: -4px;
}

.sub_tab_btn ul li {
  display: inline-block;
  vertical-align: top;
}

.sub_tab_btn ul li a {
  display: table;
  width: 100%;
  height: 50px;
  color: #666666;
  font-weight: 400;
  font-size: 18px;
  letter-spacing: -0.04em;
  text-align: center;
  border: 1px solid #bfbfbf;
  box-sizing: border-box;
  word-break: keep-all;
}

.sub_tab_btn ul li a span {
  display: table-cell;
  vertical-align: middle;
}

.sub_tab_btn ul li + li a {
  border-left: none;
}

.sub_tab_btn ul li.on a {
  color: #222222;
  font-weight: 500;
  border-top: 2px solid #ff7200;
  border-bottom: 1px solid transparent;
}

.sub_tab_btn.grid4 ul li {
  width: 8.33%;
}

.sub_tab_btn.grid5 ul li {
  width: 11.1%;
}

.sub_tab_btn.grid6 ul li {
  width: 16.6666%;
}

.sub_tab_btn.grid7 ul li {
  width: 14.2857%;
}

@media all and (max-width: 1400px) {
  .sub_tab_btn ul li a {
    min-height: 58px;
  }
}

@media all and (max-width: 1000px) {
  .sub_tab_wrap {
    overflow-x: auto;
  }

  .sub_tab_btn {
    margin-bottom: 30px;
    width: 1400px;
  }

  .sub_tab_btn ul li a {
    font-size: 16px;
  }
}

.pager_custom .programPage {
  margin-top: 80px;
}

.pager_custom .programPage a {
  vertical-align: middle;
  color: #888888;
  font-weight: 300;
  font-size: 18px;
}

.pager_custom .programPage a.active {
  color: #ff7200;
  font-weight: 700;
  border-color: transparent;
}

.pager_custom .programPage a.page_prev {
  border-color: transparent;
  margin: 0 15px;
}

.pager_custom .programPage a.page_next {
  border-color: transparent;
  margin: 0 15px;
}

.pager_custom .programPage a:hover {
  background: none;
  border-color: transparent;
}

/* sub_path */
.sub_path.write02 {
  padding-top: 50px;
}

.sub_path {
  padding: 25px 0 10px;
  font-size: 0;
  letter-spacing: -4px;
}

.sub_path .home {
  display: inline-block;
  vertical-align: middle;
}

.sub_path .home a {
  color: #888888;
  font-weight: 300;
  font-size: 18px;
  letter-spacing: -0.04em;
}

.sub_path .depth01 {
  display: inline-block;
  vertical-align: middle;
}

.sub_path .depth01:before {
  display: inline-block;
  vertical-align: middle;
  width: 16px;
  height: 1px;
  background: #ff7200;
  margin: 0 10px;
  content: '';
}

.sub_path .depth01 a {
  display: inline-block;
  vertical-align: middle;
  color: #222222;
  font-weight: 500;
  font-size: 18px;
  letter-spacing: -0.04em;
  margin: 0 2px;
}

.sub_path .depth01 a span {
  display: inline-block;
  vertical-align: middle;
}

.sub_path .depth01 a .arrow_box {
  position: relative;
  width: 17px;
  height: 17px;
  border: 1px solid #000000;
  box-sizing: border-box;
  margin-left: 5px;
}

.sub_path .depth01 a .arrow_box i {
  position: absolute;
  left: calc(50% - 3.5px);
  top: calc(50% - 2px);
  display: block;
  width: 7px;
  height: 4px;
  overflow: hidden;
}

.sub_path .depth01 a .arrow_box i:before {
  position: absolute;
  left: calc(50% - 5px);
  top: -8px;
  display: block;
  width: 8px;
  height: 8px;
  border: 1px solid #000000;
  content: '';
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
}

/* 상품리스트 */
.ShopCon_custom.ShopCon_menu_Con {
  padding-top: 0;
  padding-bottom: 150px;
}

.ShopCon_custom.ShopCon_menu_Con .side {
  display: none;
}

.ShopCon_custom.ShopCon_menu_Con .inner1000 {
  width: 100%;
  float: none;
  margin-left: 0;
}

.ShopCon_custom.ShopCon_menu_Con .content_tit {
  margin: 0;
  margin-bottom: 90px;
}

.ShopCon_custom.ShopCon_menu_Con .custom_sub01 .content_tit {
  margin-bottom: 0;
}

.ShopCon_custom.ShopCon_menu_Con .content_tit .shop_tit {
  height: 450px;
  display: table;
  width: 100%;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

.ShopCon_custom.ShopCon_menu_Con .content_tit .shop_tit .txt {
  display: table-cell;
  vertical-align: middle;
  padding: 140px;
  box-sizing: border-box;
}

.ShopCon_custom.ShopCon_menu_Con .content_tit .shop_tit .txt h2 {
  color: #222222;
  font-weight: 200;
  font-size: 50px;
  letter-spacing: -0.04em;
  line-height: 1.2;
}

.ShopCon_custom.ShopCon_menu_Con .content_tit .shop_tit .txt h2:before {
  display: block;
  width: 20px;
  height: 4px;
  background: #ff7200;
  margin-bottom: 10px;
  content: '';
}

.ShopCon_custom.ShopCon_menu_Con .content_tit .shop_tit .txt p {
  color: #222222;
  font-weight: 300;
  font-size: 20px;
  letter-spacing: -0.04em;
  margin-top: 10px;
}

.ShopCon_custom.ShopCon_menu_Con .content_tit .right_btn {
  float: none;
  font-size: 0;
  letter-spacing: -4px;
}

.ShopCon_custom.ShopCon_menu_Con .content_tit .sub_style_change {
  float: none;
  display: inline-block;
  vertical-align: middle;
  width: calc(100% - 315px);
  margin-left: 0;
  background: #f9f9f9;
  border: 1px solid #e5e5e5;
  border-right: none;
  box-sizing: border-box;
  padding: 15.5px 30px;
}

.ShopCon_custom.ShopCon_menu_Con .content_tit .sub_style_change a {
  color: #666666;
  font-weight: 300;
  font-size: 18px;
  letter-spacing: -0.04em;
  border: none;
  float: none;
  display: inline-block;
}

.ShopCon_custom.ShopCon_menu_Con .content_tit .sub_style_change a img {
  display: inline-block;
  vertical-align: middle;
}

.ShopCon_custom.ShopCon_menu_Con .content_tit .sub_style_change a span {
  display: inline-block;
  vertical-align: middle;
  margin-left: 10px;
}

.ShopCon_custom.ShopCon_menu_Con .content_tit .sub_style_change a + a:before {
  display: inline-block;
  vertical-align: middle;
  width: 1px;
  height: 16px;
  background: #e5e5e5;
  margin: 0 24px;
  content: '';
}

.ShopCon_custom.ShopCon_menu_Con .select_box {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 315px;
  height: 60px;
}

.ShopCon_custom.ShopCon_menu_Con .select_box select {
  border: none;
  background: none;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 100%;
  height: 100%;
  padding-left: 0;
  border: 1px solid #e5e5e5;
  background: #fff;
  color: #222222;
  font-weight: 300;
  font-size: 18px;
  letter-spacing: -0.04em;
  padding: 0 50px 0 20px;
  box-sizing: border-box;
}

.ShopCon_custom.ShopCon_menu_Con .sub_cont01 {
  margin-top: 60px;
}

.ShopCon_custom.ShopCon_menu_Con .sub_list {
  font-size: 0;
  letter-spacing: -4px;
}

.ShopCon_custom.ShopCon_menu_Con .sub_list .box {
  margin: 0;
  display: inline-block;
  vertical-align: top;
  margin-right: 20px;
}

.ShopCon_custom.ShopCon_menu_Con .sub_list .box .img_box {
  padding: 0;
}

.ShopCon_custom.ShopCon_menu_Con .sub_list .box .img_box .img {
  position: relative;
  padding: 39.7% 0;
  overflow: hidden;
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  border-radius: 10px;
  background: #fff;
}

.ShopCon_custom.ShopCon_menu_Con .sub_list .box .img_box .img:after {
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border: 3px solid #ff7200;
  box-sizing: border-box;
  opacity: 0;
  content: '';
  border-radius: 10px;
  transition: all 0.3s;
}

.ShopCon_custom.ShopCon_menu_Con .sub_list .box .text {
  min-height: 0;
  margin: 0;
  padding-top: 25px;
}

.ShopCon_custom.ShopCon_menu_Con .sub_list .box .text .left_tit .dc {
  color: #ff7200;
  font-weight: 700;
  font-size: 24px;
  letter-spacing: -0.04em;
}

.ShopCon_custom.ShopCon_menu_Con .sub_list .box .text .right_tit .price {
  color: #222222;
  font-weight: 700;
  font-size: 24px;
  letter-spacing: -0.04em;
  margin-right: 0;
}

.ShopCon_custom.ShopCon_menu_Con .sub_list .box .text .before_price {
  display: none;
}

.ShopCon_custom.ShopCon_menu_Con .sub_list .box h1 {
  color: #444444;
  font-weight: 400;
  font-size: 18px;
  letter-spacing: -0.04em;
  margin: 0;
  margin: 5px 0 0;
  line-height: 1.6;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  width: 100%;
}

.ShopCon_custom.ShopCon_menu_Con .sub_list .box .site {
  color: #666666;
  font-weight: 300;
  font-size: 18px;
  letter-spacing: -0.04em;
  line-height: 1.6;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.ShopCon_custom.ShopCon_menu_Con .sub_list .box .numm {
  display: none;
}

.ShopCon_custom.ShopCon_menu_Con .sub_list .btn_box {
  display: none;
}

.ShopCon_custom.ShopCon_menu_Con .sub_list.sub_list2 .box {
  position: relative;
  display: block;
  padding: 30px 0;
  padding-left: 30px;
  border-bottom-color: #eeeeee;
  border-top-color: transparent;
  padding-right: 15px;
  height: 198px;
}

.ShopCon_custom.ShopCon_menu_Con .sub_list.sub_list2 .box + .box {
  border-top-color: #eeeeee;
}

.ShopCon_custom.ShopCon_menu_Con .sub_list.sub_list2 .box > a {
  display: block;
  max-width: calc(100% - 386px);
}

.ShopCon_custom.ShopCon_menu_Con .sub_list.sub_list2 .box .img_box {
  position: static;
  display: inline-block;
  vertical-align: middle;
  width: 150px;
  height: auto;
}

.ShopCon_custom.ShopCon_menu_Con .sub_list.sub_list2 .box .img_box .img {
  position: relative;
  width: 100%;
  padding: 45.34% 0;
  border: none;
}

.ShopCon_custom.ShopCon_menu_Con .sub_list.sub_list2 .box .img_box .img img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: auto;
  max-width: 100%;
  max-height: 100%;
  margin: auto;
}

.ShopCon_custom.ShopCon_menu_Con .sub_list.sub_list2 .box .txt_box {
  display: inline-block;
  vertical-align: middle;
  padding-left: 45px;
  width: calc(100% - 195px);
}

.ShopCon_custom.ShopCon_menu_Con .sub_list.sub_list2 .box .text {
  padding-top: 0;
}

.ShopCon_custom.ShopCon_menu_Con .sub_list.sub_list2 .btn_box {
  display: block;
  position: absolute;
  right: 15px;
  top: calc(50% - 22.5px);
  font-size: 0;
  letter-spacing: -4px;
}

.ShopCon_custom.ShopCon_menu_Con .sub_list.sub_list2 .btn_box a {
  display: inline-block;
  vertical-align: top;
  width: 171px;
  height: 45px;
  border-radius: 22.5px;
  font-weight: 400;
  font-size: 18px;
  letter-spacing: -0.04em;
  line-height: 43px;
  text-align: center;
  transition: all 0.3s;
  box-sizing: border-box;
}

.ShopCon_custom.ShopCon_menu_Con .sub_list.sub_list2 .btn_box a + a {
  margin-left: 10px;
}

.ShopCon_custom.ShopCon_menu_Con .sub_list.sub_list2 .btn_box a.btn_detail {
  border: 1px solid rgba(0, 0, 0, 0.4);
  color: #222222;
}

.ShopCon_custom.ShopCon_menu_Con .sub_list.sub_list2 .btn_box a.btn_buy {
  border: 1px solid rgba(255, 114, 0, 0.4);
  color: #ff7200;
}

.ShopCon_custom.ShopCon_menu_Con .sub_list.grid5 .box {
  width: calc(20% - 16px);
}

.ShopCon_custom.ShopCon_menu_Con .sub_list.grid5 .box:nth-of-type(5n) {
  margin-right: 0;
}

.ShopCon_custom.ShopCon_menu_Con .sub_list.grid5 .box:nth-of-type(n + 6) {
  margin-top: 35px;
}

.ShopCon_custom.ShopCon_menu_Con .sub_list.grid5.sub_list2 .box {
  width: 100%;
}

.ShopCon_custom.ShopCon_menu_Con .sub_list.grid5.sub_list2 .box:nth-of-type(5n) {
  margin-right: 0;
}

.ShopCon_custom.ShopCon_menu_Con .sub_list.grid5.sub_list2 .box:nth-of-type(n + 6) {
  margin-top: 35px;
}

.ShopCon_custom.ShopCon_menu_Con .sub_list .box:hover .img_box .img:after {
  opacity: 1;
}

.ShopCon_custom.ShopCon_menu_Con .sub_list.sub_list2 .box:hover .img_box .img:after {
  opacity: 0;
}

.ShopCon_custom.ShopCon_menu_Con .sub_list.sub_list2 .btn_box a.btn_detail:hover {
  border: 1px solid rgba(0, 0, 0, 1);
}

.ShopCon_custom.ShopCon_menu_Con .sub_list.sub_list2 .btn_box a.btn_buy:hover {
  border: 1px solid rgba(255, 114, 0, 1);
}

@media all and (max-width: 1400px) {
  .ShopCon_custom.ShopCon_menu_Con .content_tit .shop_tit {
    height: 400px;
  }

  .ShopCon_custom.ShopCon_menu_Con .content_tit .shop_tit .txt {
    padding: 80px;
  }

  .ShopCon_custom.ShopCon_menu_Con .sub_list .box .text {
    padding-top: 15px;
  }

  .ShopCon_custom.ShopCon_menu_Con .sub_list .box .text .left_tit .dc {
    font-size: 22px;
  }

  .ShopCon_custom.ShopCon_menu_Con .sub_list .box .text .right_tit .price {
    font-size: 22px;
  }
}

@media all and (max-width: 1000px) {
  .ShopCon_custom.ShopCon_menu_Con {
    padding-bottom: 0;
  }

  .ShopCon_custom.ShopCon_menu_Con .content_tit {
    margin-bottom: 60px;
  }

  .ShopCon_custom.ShopCon_menu_Con .content_tit .shop_tit {
    height: 360px;
  }

  .ShopCon_custom.ShopCon_menu_Con .content_tit .shop_tit .txt {
    padding: 40px;
  }

  .ShopCon_custom.ShopCon_menu_Con .content_tit .shop_tit .txt h2 {
    font-size: 40px;
  }

  .ShopCon_custom.ShopCon_menu_Con .content_tit .shop_tit .txt p {
    font-size: 18px;
  }

  .ShopCon_custom.ShopCon_menu_Con .content_tit .sub_style_change {
    padding: 17px 20px;
  }

  .ShopCon_custom.ShopCon_menu_Con .content_tit .sub_style_change a {
    font-size: 16px;
  }

  .ShopCon_custom.ShopCon_menu_Con .content_tit .sub_style_change {
    width: calc(100% - 240px);
  }

  .ShopCon_custom.ShopCon_menu_Con .select_box {
    width: 240px;
  }

  .ShopCon_custom.ShopCon_menu_Con .select_box select {
    font-size: 16px;
  }

  .ShopCon_custom.ShopCon_menu_Con .sub_cont01 {
    margin-top: 30px;
  }

  .ShopCon_custom.ShopCon_menu_Con .sub_list .box h1 {
    font-size: 16px;
  }

  .ShopCon_custom.ShopCon_menu_Con .sub_list .box .site {
    font-size: 16px;
  }

  .ShopCon_custom.ShopCon_menu_Con .sub_list.sub_list2 .box {
    height: auto;
    padding: 20px 0;
  }

  .ShopCon_custom.ShopCon_menu_Con .sub_list.sub_list2 .box > a {
    max-width: 100%;
  }

  .ShopCon_custom.ShopCon_menu_Con .sub_list.sub_list2 .box .txt_box {
    padding-left: 30px;
  }

  .ShopCon_custom.ShopCon_menu_Con .sub_list.sub_list2 .btn_box {
    position: static;
    margin-top: 20px;
  }

  .ShopCon_custom.ShopCon_menu_Con .sub_list.sub_list2 .btn_box a {
    width: calc(50% - 5px);
    font-size: 16px;
  }

  .ShopCon_custom.ShopCon_menu_Con .sub_list.grid5 .box {
    width: calc(33.33333% - 13.333333px);
  }

  .ShopCon_custom.ShopCon_menu_Con .sub_list.grid5 .box:nth-of-type(5n) {
    margin-right: 20px;
  }

  .ShopCon_custom.ShopCon_menu_Con .sub_list.grid5 .box:nth-of-type(3n) {
    margin-right: 0;
  }

  .ShopCon_custom.ShopCon_menu_Con .sub_list.grid5 .box:nth-of-type(n + 4) {
    margin-top: 35px;
  }

  .ShopCon_custom.ShopCon_menu_Con .sub_list.grid5.sub_list2 .box {
    width: 100%;
  }

  .ShopCon_custom.ShopCon_menu_Con .sub_list.grid5.sub_list2 .box:nth-of-type(5n) {
    margin-right: 0;
  }

  .ShopCon_custom.ShopCon_menu_Con .sub_list.grid5.sub_list2 .box:nth-of-type(n + 4) {
    margin-top: 0;
  }
}

@media all and (max-width: 767px) {
  .ShopCon_custom.ShopCon_menu_Con .content_tit .shop_tit {
    height: 280px;
  }

  .ShopCon_custom.ShopCon_menu_Con .content_tit .shop_tit .txt {
    padding: 30px;
  }

  .ShopCon_custom.ShopCon_menu_Con .content_tit .shop_tit .txt h2 {
    font-size: 32px;
  }

  .ShopCon_custom.ShopCon_menu_Con .content_tit .shop_tit .txt p {
    font-size: 16px;
    margin-top: 5px;
  }

  .ShopCon_custom.ShopCon_menu_Con .content_tit .sub_style_change {
    padding: 21px 20px;
  }

  .ShopCon_custom.ShopCon_menu_Con .content_tit .sub_style_change a span {
    display: none;
  }

  .ShopCon_custom.ShopCon_menu_Con .sub_list.grid5 .box {
    width: calc(50% - 10px);
  }

  .ShopCon_custom.ShopCon_menu_Con .sub_list.grid5 .box:nth-of-type(3n) {
    margin-right: 20px;
  }

  .ShopCon_custom.ShopCon_menu_Con .sub_list.grid5 .box:nth-of-type(2n) {
    margin-right: 0;
  }

  .ShopCon_custom.ShopCon_menu_Con .sub_list.grid5 .box:nth-of-type(n + 3) {
    margin-top: 35px;
  }

  .ShopCon_custom.ShopCon_menu_Con .sub_list.grid5.sub_list2 .box {
    width: 100%;
  }

  .ShopCon_custom.ShopCon_menu_Con .sub_list.grid5.sub_list2 .box:nth-of-type(3n) {
    margin-right: 0;
  }

  .ShopCon_custom.ShopCon_menu_Con .sub_list.grid5.sub_list2 .box:nth-of-type(n + 3) {
    margin-top: 0;
  }
}

.shopList_custom.shopList .left {
  width: calc(50% - 30px);
  margin-right: 30px;
}

.shopList_custom.shopList .left .gal_slider {
  width: 100%;
}

.shopList .left .gal_slider.custom_gal {
  margin-top: 20px;
}

.shopList_custom.shopList .left .gal_slider li {
  margin: 0 5px;
}

.shopList_custom.shopList .left .slick-list .slick-slide .img_box {
  position: relative;
  padding: 50% 0;
  overflow: hidden;
  border: 1px solid #ccc;
  margin-top: 33px;
}

.ShopCon.shopList.shopList_custom.fast_read_custom .con01 .left ul li .img_box {
  margin-top: 0;
}

.shopList_custom.shopList .left .slick-list .slick-slide .img_box img {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 90%;
  height: auto;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
}

/*.shopList_custom.shopList .left .slick-list .slick-slide .img_box.pad_ano img {left: 0; top: 0; right:0; bottom:0; max-width:100%; max-height:100%; margin:auto; width: auto; transform: none; -webkit-transform: none; -moz-transform: none;-o-transform: none; -ms-transform: none;}*/
.shopList_custom.shopList .right {
  width: 50%;
  padding: 30px 27px 60px 93px;
  box-sizing: border-box;
}

.shopList_custom.shopList .right .tit {
  border-top: none;
  border-bottom: none;
  padding: 0;
}

.shopList_custom.shopList .right .tit .tit_bor {
  border-bottom: 1px solid #ccc;
  box-sizing: border-box;
  padding-bottom: 20px;
}

.shopList_custom.shopList .right .tit .tit_bor p {
  color: #444444;
  font-weight: 700;
  font-size: 28px;
  letter-spacing: -0.04em;
  line-height: 1.6;
}

.shopList_custom.shopList .right .tit > h2 {
  font-size: 16px;
  line-height: 2.9;
  margin-top: 20px;
}

.shopList_custom.shopList .right .tit > h2 b {
  display: none;
}

.shopList_custom.shopList .right .tit > h2 p {
  font-size: 0;
  letter-spacing: -4px;
}

.shopList_custom.shopList .right .tit > h2 p strong {
  display: inline-block;
  vertical-align: middle;
  width: 100px;
  color: #444444;
  font-weight: 500;
  font-size: 18px;
  letter-spacing: -0.045em;
}

.shopList_custom.shopList .right .tit > h2 p span {
  display: inline-block;
  vertical-align: middle;
  width: calc(100% - 100px);
}

.shopList_custom.shopList .right .tit > h2 .price {
  color: #888888;
  font-weight: 400;
  font-size: 18px;
  letter-spacing: -0.025em;
}

.shopList_custom.shopList .right .tit > h2 .before_price {
  color: #aaaaaa;
  font-weight: 400;
  font-size: 18px;
  letter-spacing: -0.025em;
  text-decoration: line-through;
}

.shopList_custom.shopList .right .tit > h2 .before_price:after {
  display: none;
}

.shopList_custom.shopList .right .card {
  margin-top: 0;
}

.shopList_custom.shopList .right .card p {
  font-size: 0;
  letter-spacing: -4px;
  padding-top: 0;
  line-height: 2.9;
}

.shopList_custom.shopList .right .card p strong {
  display: inline-block;
  vertical-align: middle;
  width: 95px;
  color: #444444;
  font-weight: 500;
  font-size: 16px;
  letter-spacing: -0.045em;
}

.shopList_custom.shopList .right .card p span {
  display: inline-block;
  vertical-align: middle;
  width: calc(100% - 95px);
  color: #888888;
  font-weight: 400;
  font-size: 16px;
  letter-spacing: -0.025em;
  height: auto;
  background: none;
  margin: 0;
  position: static;
}

.shopList_custom.shopList .option_select {
  margin-bottom: 0;
  font-size: 0;
  letter-spacing: -4px;
  min-height: 0;
  padding-top: 30px;
  border-top: 1px solid #cccccc;
}

.shopList_custom.shopList .option_select strong {
  display: inline-block;
  vertical-align: middle;
  width: 95px;
  color: #444444;
  font-weight: 500;
  font-size: 16px;
  letter-spacing: -0.025em;
  text-align: left;
}

.shopList_custom.shopList .option_select .select_bx {
  display: inline-block;
  vertical-align: middle;
  width: calc(100% - 95px);
}

.shopList_custom.shopList .option_select .select_txt {
  color: #999999;
  font-weight: 300;
  font-size: 14px;
  letter-spacing: -0.045em;
  height: 35px;
  border: 1px solid #dddddd;
  box-sizing: border-box;
  line-height: 33px;
}

.shopList_custom.shopList .option_select .select_txt .ico_arr {
  width: 8px;
  height: 6px;
  top: calc(50% - 3px);
  margin-top: 0;
  right: 20px;
}

.shopList_custom.shopList .option_select .option_list_wrap {
  top: 35px;
}

.shopList_custom.shopList .option_select .option_list_wrap li .right_box {
  letter-spacing: -0.045em;
}

.shopList_custom.shopList .bot_btn_more {
  font-size: 0;
  letter-spacing: -4px;
  height: 74px;
  margin-top: 80px;
  text-align: right;
}

.shopList_custom.shopList .bot_btn_more a {
  display: inline-block;
  width: 200px;
  height: 50px !important;
  vertical-align: top;
  height: 100%;
  font-size: 20px;
  letter-spacing: -0.045em;
  line-height: 50px;
  float: none;
  background: #fff;
  color: #222;
  border: 1px solid #222;
}

.shopList_custom.shopList .bot_btn_more a.save {
  display: none;
}

.shopList_custom.shopList .bot_btn_more a.go_carts {
  width: 200px;
  height: 50px;
  line-height: 50px;
  display: inline-block;
  background: #666;
  border: 1px solid #666;
  color: #666666;
  font-weight: 400;
  margin-right: 10px;
  color: #fff;
}

.shopList_custom.shopList .bot_btn_more a.go_buys {
  width: 200px;
  height: 50px;
  line-height: 50px;
  display: inline-block;
  background: #fff;
  border: 1px solid #222;
  color: #222;
}

.shopList_custom .select_after .delivery_text {
  display: none;
}

.shopList_custom .select_after .box ul + ul {
  margin-top: 10px;
}

.shopList_custom .select_after .box ul li {
  padding: 30px;
}

.shopList_custom .select_after .box ul li .top .x_btn {
  right: 30px;
  top: 30px;
  margin-top: 0;
  width: 11px;
  height: 11px;
}

.shopList_custom .select_after .box ul li .top .text {
  color: #888888;
  font-weight: 500;
  font-size: 16px;
  letter-spacing: -0.045em;
  line-height: 1.7;
}

.shopList_custom .select_after .box ul li .bot .boxx .option_add .minus {
  width: 30px;
  height: 30px;
}

.shopList_custom .select_after .box ul li .bot .boxx .option_add .plus {
  width: 30px;
  height: 30px;
}

.shopList_custom .select_after .box ul li .bot .boxx .option_add input {
  width: 60px;
  height: 30px;
  color: #333333;
  font-weight: 200;
  font-size: 16px;
  letter-spacing: -0.025em;
}

.shopList_custom .select_after .box ul li .bot .boxx .price {
  color: #222222;
  font-weight: 400;
  font-size: 16px;
  letter-spacing: -0.045em;
}

.shopList_custom .select_after .box ul li .bot .boxx .price span {
  font-size: 16px;
}

.shopList_custom .select_after .total_price {
  font-size: 0;
  letter-spacing: -4px;
  padding-top: 30px;
  border-top: 1px solid #cccccc;
  margin-top: 30px;
}

.shopList_custom .select_after .total_price p {
  font-size: 16px;
}

.shopList_custom .select_after .total_price strong {
  display: inline-block;
  vertical-align: middle;
  width: 95px;
  color: #444444;
  font-weight: 500;
  font-size: 16px;
  letter-spacing: -0.025em;
  text-align: left;
}

.shopList_custom .select_after .total_price span {
  display: inline-block;
  vertical-align: middle;
  width: calc(100% - 95px);
  text-align: right;
  color: #999999;
  font-weight: 200;
  font-size: 16px;
  letter-spacing: -0.045em;
  margin-left: 0;
}

.shopList_custom .select_after .total_price span b {
  color: #ff9100;
  font-weight: 500;
  font-size: 30px;
  letter-spacing: -0.045em;
  margin-right: 5px;
}

@media all and (max-width: 1400px) {
  .shopList_custom.shopList .right {
    padding-left: 50px;
  }
}

@media all and (max-width: 1000px) {
  .shopList_custom.shopList {
    padding: 0 0 100px;
  }

  .shopList_custom.shopList .left {
    width: 100%;
    margin-right: 0;
  }

  .shopList_custom.shopList .right {
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
  }

  .shopList_custom.shopList .bot_btn_more {
    margin-top: 40px;
  }

  .shopList_custom.shopList.fast_read_custom .left {
    width: 100%;
  }
}

/* 공통 구매 */
.buy_con01 {
  font-size: 0;
  letter-spacing: -4px;
}

.buy_con01_bg {
  background: #f9fffb;
  border: 1px solid #ceebd7;
  box-sizing: border-box;
  margin: 40px 0 20px 0;
  padding: 0 40px;
}

.buy_con01_txtbox {
  padding: 40px 0 15px 0;
  word-break: keep-all;
  border-bottom: 1px solid #030000;
  box-sizing: border-box;
}

.buy_con01_txtbox p {
  font-size: 30px;
  color: #222;
  letter-spacing: -0.04em;
  font-weight: 500;
}

.buy_con01_txtbox p b {
  font-size: 18px;
  letter-spacing: -0.04em;
  color: #999999;
  font-weight: 300;
  margin-left: 10px;
}

.buy_con01_txtbox p br {
  display: none;
}

.buy_con01_imgbox ul {
  width: 100%;
  text-align: center;
  padding-top: 20px;
  padding-bottom: 30px;
}

.buy_con01_imgbox ul li {
  display: inline-block;
  vertical-align: top;
  width: calc(14.28% - 8.57px);
  margin-right: 10px;
  box-sizing: border-box;
}

.buy_con01_imgbox ul li:last-of-type {
  margin-right: 0;
}

.buy_con01_imgbox ul li .circle {
  margin: 0 auto 15px;
  border: 3px solid #e1f3e7;
  background: #fff;
  border-radius: 50%;
  width: 180px;
  height: 180px;
  position: relative;
  box-sizing: border-box;
}

.buy_con01_imgbox ul li .circle i {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.buy_con01_imgbox ul li span {
  font-size: 18px;
  letter-spacing: -0.04em;
  color: #269521;
  font-weight: 500;
}

.buy_con01_imgbox ul li p {
  font-size: 18px;
  letter-spacing: -0.04em;
  color: #222222;
  font-weight: 500;
}

@media all and (max-width: 1400px) {
  .buy_con01_imgbox ul li {
    width: calc(33.33% - 13.33px);
    margin-right: 20px;
  }

  .buy_con01_imgbox ul li:nth-of-type(3n) {
    margin-right: 0;
  }
}

@media all and (max-width: 1000px) {
  .buy_con01_imgbox ul li {
    width: calc(50% - 10px);
    margin-right: 20px;
  }

  .buy_con01_imgbox ul li:nth-of-type(2n) {
    margin-right: 0;
  }

  .buy_con01_imgbox ul li:last-of-type {
    display: block;
  }

  .buy_con01_txtbox p br {
    display: block;
  }
}

.buy_con02 {
  font-size: 0;
  letter-spacing: -4px;
  word-break: keep-all;
  margin-bottom: 20px;
}

.buy_con02 .buy_con02_txtbox {
  padding-bottom: 30px;
}

.buy_con02 .buy_con02_txtbox p {
  font-size: 30px;
  color: #222;
  letter-spacing: -0.04em;
  font-weight: 500;
}

.buy_con02 .buy_con02_txtbox span {
  font-size: 18px;
  color: #666;
  letter-spacing: -0.04em;
  font-weight: 500;
}

.buy_con02 .buy_con02_listbox {
  text-align: center;
}

.buy_con02 .buy_con02_listbox ul {
  height: 300px;
  display: table;
  width: 100%;
}

.buy_con02 .buy_con02_listbox ul li {
  display: table-cell;
  vertical-align: top;
  width: calc(33.3333% - 13.3333px);
  margin-right: 20px;
  box-sizing: border-box;
  border-radius: 3px;
  position: relative;
}

.buy_con02 .buy_con02_listbox ul li:last-of-type {
  margin-right: 0;
}

.buy_con02 .buy_con02_listbox ul li .buy_con02_imgbox img {
  display: inline-block;
  width: 450px;
  height: 300px;
}

.buy_con02 .buy_con02_listbox .buy_con02_listtxt {
  height: 60px;
  background: #666666;
  position: absolute;
  width: 450px;
  bottom: 0px;
  left: 8px;
}

.buy_con02 .buy_con02_listbox .buy_con02_listtxt p {
  font-size: 18px;
  color: #fff;
  letter-spacing: -0.04em;
  font-weight: 500;
}

/*일빵이야기*/
.content_story {
  padding: 0px 80px;
}

.buy_con03.custom_pad {
  padding-top: 12px;
}

.buy_con03_tit span {
  display: block;
  font-size: 30px;
  font-weight: 500;
  color: #222222;
  letter-spacing: -0.04em;
  padding-top: 20px;
}

.buy_con03_txt {
  margin-top: 20px;
}

.buy_con03_txt p {
  font-size: 18px;
  font-weight: 300;
  line-height: 1.5;
  color: #666666;
  letter-spacing: -0.04em;
  word-break: keep-all;
}

.buy_con03_list {
  border: 1px solid #ebe8d5;
  box-sizing: border-box;
  padding: 0 40px;
  margin-top: 25px;
}

.buy_con03_list ul {
}

.buy_con03_list ul li {
  padding: 30px 40px;
  font-size: 0;
  letter-spacing: -4px;
  border-bottom: 1px dashed #ebe8d5;
}

.buy_con03_list ul li p {
  position: relative;
}

.buy_con03_list ul li p:before {
  position: absolute;
  content: '';
  width: 24px;
  height: 24px;
  display: inline-block;
  vertical-align: middle;
  background: url('/src/assets/img/icon/buy_com03_icon.png') no-repeat;
  left: -3%;
  top: 3px;
}

.buy_con03_list ul li p b {
  width: 60px;
  display: inline-block;
  vertical-align: top;
  font-size: 18px;
  color: #ff7200;
  letter-spacing: -0.04em;
  font-weight: 500;
}

.buy_con03_list ul li p span {
  width: calc(100% - 60px);
  display: inline-block;
  vertical-align: top;
  font-size: 18px;
  color: #666666;
  letter-spacing: -0.04em;
  font-weight: 300;
}

@media all and (max-width: 1000px) {
  .buy_con03_list ul li p:before {
    left: -10%;
  }
}

.buy_con04 {
  padding-top: 45px;
}

.buy_con04 .buy_con04_tit span {
  display: block;
  font-size: 30px;
  font-weight: 500;
  color: #222222;
  letter-spacing: -0.04em;
}

.buy_con04 .buy_con04_txt {
  margin-top: 20px;
}

.buy_con04 .buy_con04_txt p {
  font-size: 18px;
  font-weight: 300;
  line-height: 1.5;
  color: #666666;
  letter-spacing: -0.04em;
}

.buy_con04 .buy_con04_txt_list_box ul {
  letter-spacing: -4px;
  font-size: 0;
}

.buy_con04 .buy_con04_txt_list_box ul li {
  display: inline-block;
  vertical-align: top;
  width: calc(20% - 16px);
  margin-right: 20px;
  border: 1px solid #ebe8d5;
  box-sizing: border-box;
}

.buy_con04 .buy_con04_txt_list_box {
  padding: 26px 0 45px 0;
}

.buy_con04 .buy_con04_txt_list_box ul li:nth-of-type(5n) {
  margin-right: 0;
}

.buy_con04 .buy_con04_txt_list_box ul li {
  text-align: center;
  padding: 25px 20px 0;
  min-height: 143px;
}

.buy_con04 .buy_con04_txt_list_box ul li span {
  font-size: 18px;
  letter-spacing: -0.04em;
  color: #ff7608;
  font-weight: 500;
  position: relative;
}

.buy_con04 .buy_con04_txt_list_box ul li span:after {
  content: '';
  position: absolute;
  width: 20px;
  height: 1px;
  left: 0;
  bottom: -5px;
  background: #ff7608;
}

.buy_con04 .buy_con04_txt_list_box ul li p {
  margin-top: 20px;
  font-size: 18px;
  letter-spacing: -0.04em;
  color: #222222;
  font-weight: 500;
  word-break: keep-all;
}

@media all and (max-width: 1000px) {
  .buy_con04 .buy_con04_txt_list_box ul li {
    width: calc(50% - 10px);
    margin-right: 20px;
  }

  .buy_con04 .buy_con04_txt_list_box ul li:nth-of-type(2n) {
    margin-right: 0;
  }

  .buy_con04 .buy_con04_txt_list_box ul li:nth-of-type(n + 3) {
    margin-top: 20px;
  }
}

/*@media all and (max-width: 767px) {*/
/*	.buy_con04 .buy_con04_txt p br {display:none;}*/
/*	.buy_con04 .buy_con04_txt_list_box ul li {width:100%; margin-right:20px;}*/
/*	.buy_con04 .buy_con04_txt_list_box ul li:nth-of-type(2n) {margin-right:0;}*/
/*	.buy_con04 .buy_con04_txt_list_box ul li + li {margin-top:20px;}*/
/*}*/
.buy_con03.buy_con05 {
  padding-bottom: 45px;
}

.buy_con03.buy_con05 .buy_con03_txt {
  margin: 15px 0;
}

.buy_con03.buy_con05 .buy_con05_list ul li + li {
  margin-top: 15px;
}

.buy_con03.buy_con05 .buy_con05_list ul li b {
  display: inline-block;
  vertical-align: top;
  font-size: 18px;
  color: #ff7608;
  font-weight: 500;
  letter-spacing: -0.04em;
}

.buy_con03.buy_con05 .buy_con05_list ul li span {
  margin-left: 5px;
  display: inline-block;
  vertical-align: top;
  font-size: 18px;
  color: #222222;
  font-weight: 500;
  letter-spacing: -0.04em;
}

.buy_con06 .img_box {
}

.buy_con06 .img_box ul {
  letter-spacing: -4px;
  font-size: 0;
}

.buy_con06 .img_box ul li {
  display: inline-block;
  vertical-align: top;
  width: calc(33.33% - 13.33px);
  margin-right: 20px;
}

.buy_con06 .img_box ul li img {
  width: 100%;
}

.buy_con06 .img_box ul li:nth-of-type(3n) {
  margin-right: 0;
}

.buy_con04.buy_con07 {
  padding-top: 46px;
  padding-bottom: 0;
}

.buy_con04.buy_con07 .buy_con04_tit span {
  color: #269521;
}

.buy_con04.buy_con07 .buy_con04_tit {
  display: inline-block;
  vertical-align: middle;
  width: calc(100% - 140px);
}

.buy_con04.buy_con07 .btn_box {
  display: inline-block;
  vertical-align: middle;
  width: 135px;
}

.buy_con04.buy_con07 .btn_box a {
  display: inline-block;
  vertical-align: middle;
  font-size: 16px;
  font-weight: 400;
  color: #999999;
  letter-spacing: -0.04em;
}

.buy_con04.buy_con07 .btn_box a i {
  display: inline-block;
  vertical-align: middle;
  margin-bottom: 2px;
}

.buy_con04.buy_con07 .buy_con04_txt_list_box {
  padding-bottom: 0;
}

.buy_con04.buy_con07 .buy_con04_txt_list_box ul li span {
  color: #269521;
}

.buy_con04.buy_con07 .buy_con04_txt_list_box ul li span:after {
  background: #269521;
}

.buy_con08 {
  padding-bottom: 30px;
}

.buy_con08 .buy_con08_con01 {
  background: #f9fffb;
  border: 1px solid #ceebd7;
  padding: 40px 40px 30px 40px;
}

.buy_con08 .buy_con08_con01 .tit_box br {
  display: none;
}

.buy_con08 .buy_con08_con01 .tit_box span {
  font-size: 24px;
  letter-spacing: -0.04em;
  color: #222;
  font-weight: 700;
}

.buy_con08 .buy_con08_con01 .tit_box span b {
  margin-left: 20px;
  font-size: 18px;
  letter-spacing: -0.04em;
  color: #999999;
  font-weight: 300;
}

.buy_con08 .buy_con08_con01 .list_box ul li {
  position: relative;
  display: inline-block;
  vertical-align: top;
  width: calc(16.66% - 12.5px);
  margin-right: 12px;
  box-sizing: border-box;
}

.buy_con08 .buy_con08_con01 .list_box ul {
  padding-top: 50px;
  letter-spacing: -4px;
  font-size: 0;
}

.buy_con08 .buy_con08_con01 .list_box ul li img {
  width: 100%;
}

.buy_con08 .buy_con08_con01 .list_box ul li:nth-of-type(6n) {
  margin-right: 0;
}

.buy_con08 .buy_con08_con01 .list_box ul li span {
  font-size: 16px;
  letter-spacing: -0.04em;
  color: #fff;
  font-weight: 500;
  background: #269521;
  width: 44px;
  height: 44px;
  line-height: 44px;
  text-align: center;
  display: block;
  border-radius: 50%;
  position: absolute;
  left: 50%;
  top: -22px;
  transform: translateX(-50%);
}

.buy_con08 .buy_con08_con01 .list_box ul li p {
  text-align: center;
  font-size: 18px;
  color: #222222;
  letter-spacing: -0.04em;
  font-weight: 500;
  margin-top: 14px;
}

.buy_con08 .buy_con08_con01 .btn_box {
  text-align: right;
}

.buy_con08_btn {
  text-align: right;
  padding-bottom: 30px;
}

.buy_con08_btn .btn_box a span {
  display: inline-block;
  font-size: 18px;
  color: #269521;
  letter-spacing: -0.04em;
  font-weight: 700;
  border: 1px solid #269521;
  border-radius: 10px;
  width: 222px;
  height: 52px;
  line-height: 52px;
  text-align: center;
  box-sizing: border-box;
}

.buy_con08_btn .btn_box:hover span {
  border: 3px solid #269521;
  box-sizing: border-box;
}

@media all and (max-width: 1200px) {
  .buy_con08 .buy_con08_con01 .tit_box br {
    display: block;
  }

  .buy_con08 .buy_con08_con01 .list_box ul li {
    width: calc(33.33% - 13.33px);
    margin-right: 10px;
  }

  .buy_con08 .buy_con08_con01 .list_box ul li:nth-of-type(3n) {
    margin-right: 0;
  }

  .buy_con08 .buy_con08_con01 .list_box ul li:nth-of-type(n + 4) {
    margin-top: 50px;
  }
}

@media all and (max-width: 767px) {
  .buy_con08 .buy_con08_con01 .tit_box br {
    display: block;
  }

  .buy_con08 .buy_con08_con01 .list_box ul li {
    width: calc(50% - 10px);
    margin-right: 20px;
  }

  .buy_con08 .buy_con08_con01 .list_box ul li:nth-of-type(3n) {
    margin-right: 20px;
  }

  .buy_con08 .buy_con08_con01 .list_box ul li:nth-of-type(2n) {
    margin-right: 0;
  }

  .buy_con08 .buy_con08_con01 .list_box ul li:nth-of-type(n + 3) {
    margin-top: 40px;
  }
}

.buy_con09 .buy_con09_bg {
}

.buy_con09 .buy_con09_tit {
  display: inline-block;
  vertical-align: top;
  width: calc(100% - 141px);
  background: #fff;
  padding-bottom: 45px;
}

.buy_con09 .btn_box {
  width: 138px;
  display: inline-block;
  vertical-align: top;
  text-align: right;
}

.buy_con09 .btn_box a {
  font-size: 16px;
  color: #999999;
  letter-spacing: -0.04em;
  font-weight: 400;
  box-sizing: border-box;
  display: inline-block;
  vertical-align: middle;
}

.buy_con09 .btn_box a i {
  margin-left: 10px;
  display: inline-block;
  vertical-align: middle;
}

.buy_con09 .buy_con09_tit span {
  font-size: 30px;
  letter-spacing: -0.04em;
  color: #ff7200;
  font-weight: 500;
}

.buy_con09 .buy_con09_tit p {
  font-size: 18px;
  letter-spacing: -0.04em;
  color: #666666;
  font-weight: 300;
  margin-top: 20px;
}

.buy_con09 .buy_con09_bg .buy_con09_sub_tit {
  box-sizing: border-box;
  border-bottom: 1px solid #030000;
}

.buy_con09 .buy_con09_bg .buy_con09_sub_tit p {
  font-size: 24px;
  font-weight: 700;
  letter-spacing: -0.04em;
  color: #222222;
  margin-bottom: 12px;
}

.buy_con09 .buy_con09_bg .buy_con09_sub_tit p b {
  font-size: 18px;
  letter-spacing: -0.04em;
  color: #999999;
  font-weight: 300;
  margin-left: 20px;
}

.buy_con09 .buy_con09_bg .buy_con09_desc_tit {
  margin-top: 25px;
}

.buy_con09 .buy_con09_bg .buy_con09_desc_tit span {
  font-size: 20px;
  letter-spacing: -0.04em;
  color: #ff7200;
  font-weight: 700;
}

.buy_con09 .buy_con09_bg .buy_con09_list_box ul {
  letter-spacing: -4px;
  font-size: 0;
  padding: 22px 0;
}

.buy_con09 .buy_con09_bg .buy_con09_list_box ul li {
  padding: 20px 20px 0px 20px;
  width: calc(20% - 8px);
  margin-right: 10px;
  display: inline-block;
  vertical-align: top;
  text-align: center;
  border: 1px solid #ebe8d5;
  box-sizing: border-box;
  min-height: 126px;
}

.buy_con09 .buy_con09_bg .buy_con09_list_box ul li:nth-of-type(5n) {
  margin-right: 0;
}

.buy_con09 .buy_con09_bg .buy_con09_list_box ul li span {
  display: inline-block;
  font-size: 18px;
  color: #ff7608;
  letter-spacing: -0.04em;
  font-weight: 500;
  position: relative;
  padding-bottom: 25px;
}

.buy_con09 .buy_con09_bg .buy_con09_list_box ul li span:after {
  content: '';
  position: absolute;
  background: #ff7608;
  width: 20px;
  height: 1px;
  left: -40%;
  bottom: 35%;
}

.buy_con09 .buy_con09_bg .buy_con09_list_box ul li p {
  font-size: 18px;
  letter-spacing: -0.04em;
  font-weight: 500;
  color: #222222;
  word-break: keep-all;
}

.buy_con09 .buy_con09_bg .buy_con09_border_box {
  border: 1px solid #ebe8d5;
  box-sizing: border-box;
  padding: 40px;
  background: #fffef7;
}

.buy_con09 .buy_con09_bg .buy_con09_btn_box {
  text-align: right;
}

.buy_con09 .buy_con09_bg .buy_con09_btn_box a {
  font-size: 18px;
  letter-spacing: -0.04em;
  color: #ff7200;
  display: inline-block;
  box-sizing: border-box;
  border: 1px solid #ff7200;
  width: 257px;
  height: 52px;
  line-height: 52px;
  border-radius: 10px;
  text-align: center;
}

.buy_con09 .buy_con09_bg .buy_con09_btn_box a:hover {
  border: 3px solid #ff7200;
  font-weight: 700;
}

@media all and (max-width: 1000px) {
  .buy_con09 .btn_box a {
    margin-bottom: 20px;
  }

  .buy_con09 .buy_con09_bg .buy_con09_desc_tit span {
    font-size: 17px;
  }

  .buy_con09 .buy_con09_tit {
    width: 100%;
    padding-bottom: 20px;
  }

  .buy_con09 .buy_con09_bg .buy_con09_list_box ul li {
    width: calc(50% - 10px);
    margin-right: 20px;
  }

  .buy_con09 .buy_con09_bg .buy_con09_list_box ul li:nth-of-type(2n) {
    margin-right: 0;
  }

  .buy_con09 .buy_con09_bg .buy_con09_list_box ul li:nth-of-type(n + 3) {
    margin-top: 20px;
  }

  .buy_con09 .buy_con09_bg .buy_con09_btn_box {
    text-align: center;
  }

  .buy_con09 .buy_con09_bg .buy_con09_sub_tit p b {
    display: block;
    margin-left: 0;
  }
}

.buy_con09.buy_con10 .buy_con09_bg {
  background: #fffef7;
}

.buy_con09.buy_con10 .buy_con09_tit {
  display: inline-block;
  vertical-align: top;
  width: calc(100% - 141px);
  background: #fff;
  padding-bottom: 45px;
}

.buy_con09.buy_con10 .btn_box {
  width: 138px;
  display: inline-block;
  vertical-align: top;
  text-align: right;
}

.buy_con09.buy_con10 .btn_box a {
  font-size: 16px;
  color: #999999;
  letter-spacing: -0.04em;
  font-weight: 400;
  box-sizing: border-box;
  display: inline-block;
  vertical-align: middle;
}

.buy_con09.buy_con10 .btn_box a i {
  margin-left: 10px;
  display: inline-block;
  vertical-align: middle;
}

.buy_con09.buy_con10 .buy_con09_tit span {
  font-size: 30px;
  letter-spacing: -0.04em;
  color: #ff7200;
  font-weight: 500;
}

.buy_con09.buy_con10 .buy_con09_tit p {
  font-size: 18px;
  letter-spacing: -0.04em;
  color: #666666;
  font-weight: 300;
}

.buy_con09.buy_con10 .buy_con09_bg .buy_con09_sub_tit {
  box-sizing: border-box;
  border-bottom: 1px solid #030000;
}

.buy_con09.buy_con10 .buy_con09_bg .buy_con09_sub_tit p {
  font-size: 24px;
  font-weight: 700;
  letter-spacing: -0.04em;
  color: #222222;
  margin-bottom: 12px;
}

.buy_con09.buy_con10 .buy_con09_bg .buy_con09_sub_tit p b {
  font-size: 18px;
  letter-spacing: -0.04em;
  color: #999999;
  font-weight: 300;
  margin-left: 20px;
}

.buy_con09.buy_con10 .buy_con09_bg .buy_con09_desc_tit {
  margin-top: 25px;
}

.buy_con09.buy_con10 .buy_con09_bg .buy_con09_desc_tit span {
  font-size: 20px;
  letter-spacing: -0.04em;
  color: #ff7200;
  font-weight: 700;
}

.buy_con09.buy_con10 .buy_con09_bg .buy_con09_list_box ul {
  letter-spacing: -4px;
  font-size: 0;
  padding: 22px 0;
}

.buy_con09.buy_con10 .buy_con09_bg .buy_con09_list_box ul li {
  padding: 20px 20px 0px 20px;
  width: calc(20% - 8px);
  margin-right: 10px;
  display: inline-block;
  vertical-align: top;
  text-align: center;
  border: 1px solid #ebe8d5;
  box-sizing: border-box;
}

.buy_con09.buy_con10.buy_con10 .buy_con09_bg .buy_con09_list_box ul li:nth-of-type(5n) {
  margin-right: 0;
}

.buy_con09.buy_con10.buy_con10 .buy_con09_bg .buy_con09_list_box ul li span {
  display: inline-block;
  font-size: 18px;
  color: #ff7608;
  letter-spacing: -0.04em;
  font-weight: 500;
  position: relative;
  padding-bottom: 25px;
}

.buy_con09 .buy_con09_bg .buy_con09_list_box ul li span:after {
  content: '';
  position: absolute;
  background: #ff7608;
  width: 20px;
  height: 1px;
  left: -40%;
  bottom: 35%;
}

.buy_con09.buy_con10 .buy_con09_bg .buy_con09_list_box ul li p {
  font-size: 18px;
  letter-spacing: -0.04em;
  font-weight: 500;
  color: #222222;
  word-break: keep-all;
}

.buy_con09.buy_con10 .buy_con09_bg .buy_con09_border_box {
  margin: 20px 0 150px 0;
  border: 1px solid #ebe8d5;
  box-sizing: border-box;
  padding: 40px;
}

.buy_con09.buy_con10 .buy_con09_bg .buy_con09_btn_box {
  text-align: right;
}

.buy_con09.buy_con10 .buy_con09_bg .buy_con09_btn_box a {
  font-size: 18px;
  letter-spacing: -0.04em;
  color: #ff7200;
  display: inline-block;
  box-sizing: border-box;
  border: 1px solid #ff7200;
  width: 257px;
  height: 52px;
  line-height: 52px;
  border-radius: 10px;
  text-align: center;
}

.buy_con09.buy_con10 .buy_con09_bg .buy_con09_btn_box a:hover {
  border: 3px solid #ff7200;
  font-weight: 700;
}

@media all and (max-width: 1000px) {
  .buy_con09.buy_con10 .buy_con09_bg .buy_con09_sub_tit p b {
    margin-left: 0;
  }

  .buy_con09.buy_con10 .buy_con09_bg .buy_con09_desc_tit span {
    font-size: 17px;
  }

  .buy_con09.buy_con10 .buy_con09_bg .buy_con09_list_box ul li {
    width: calc(50% - 10px);
    margin-right: 20px;
  }

  .buy_con09.buy_con10 .buy_con09_bg .buy_con09_list_box ul li:nth-of-type(2n) {
    margin-right: 0;
  }

  .buy_con09.buy_con10 .buy_con09_bg .buy_con09_list_box ul li:nth-of-type(n + 3) {
    margin-top: 20px;
  }

  .buy_con09 .buy_con09_bg .buy_con09_btn_box {
    text-align: right;
  }

  .buy_con09.buy_con10 .buy_con09_bg .buy_con09_list_box ul li + li {
    margin-top: 0;
  }
}

/* fastbuy */
.fast_custom .shop_tit {
  height: 450px;
  display: table;
  width: 100%;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

.fast_custom .shop_tit .txt {
  display: table-cell;
  vertical-align: middle;
  padding: 140px;
  box-sizing: border-box;
}

.fast_custom .shop_tit .txt h2 {
  color: #222222;
  font-weight: 200;
  font-size: 50px;
  letter-spacing: -0.04em;
  line-height: 1.2;
}

.fast_custom .shop_tit .txt h2:before {
  display: block;
  width: 20px;
  height: 4px;
  background: #ff7200;
  margin-bottom: 10px;
  content: '';
}

.fast_custom .shop_tit .txt p {
  color: #222222;
  font-weight: 300;
  font-size: 20px;
  letter-spacing: -0.04em;
  margin-top: 10px;
}

.fast_custom .right_btn {
  float: none;
  font-size: 0;
  letter-spacing: -4px;
  background: #f9f9f9;
}

.fast_custom .sub_style_change {
  float: none;
  display: inline-block;
  vertical-align: middle;
  width: 100%;
  margin-left: 0;
  background: #f9f9f9;
  border: 1px solid #e5e5e5;
  border-right: none;
  box-sizing: border-box;
  padding: 15.5px 30px;
}

.fast_custom .sub_style_change a {
  color: #666666;
  font-weight: 300;
  font-size: 18px;
  letter-spacing: -0.04em;
  border: none;
  float: none;
  display: inline-block;
}

.fast_custom .sub_style_change a img {
  display: inline-block;
  vertical-align: middle;
}

.fast_custom .sub_style_change a span {
  display: inline-block;
  vertical-align: middle;
  margin-left: 10px;
}

.fast_custom .sub_style_change a + a:before {
  display: inline-block;
  vertical-align: middle;
  width: 1px;
  height: 16px;
  background: #e5e5e5;
  margin: 0 24px;
  content: '';
}

.fast_custom .select_box {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 315px;
  height: 60px;
}

.fast_custom .select_box select {
  border: none;
  background: none;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 100%;
  height: 100%;
  padding-left: 0;
  border: 1px solid #e5e5e5;
  background: #fff;
  color: #222222;
  font-weight: 300;
  font-size: 18px;
  letter-spacing: -0.04em;
  padding: 0 50px 0 20px;
  box-sizing: border-box;
}

@media all and (max-width: 1400px) {
  .fast_custom .content_tit .shop_tit {
    height: 400px;
  }

  .fast_custom .content_tit .shop_tit .txt {
    padding: 80px;
  }
}

@media all and (max-width: 1000px) {
  .fast_custom {
    padding-bottom: 100px;
  }

  .fast_custom .content_tit {
    margin-bottom: 60px;
  }

  .fast_custom .content_tit .shop_tit {
    height: 360px;
  }

  .fast_custom .content_tit .shop_tit .txt {
    padding: 40px;
  }

  .fast_custom .content_tit .shop_tit .txt h2 {
    font-size: 40px;
  }

  .fast_custom .content_tit .shop_tit .txt p {
    font-size: 18px;
  }

  .fast_custom .content_tit .sub_style_change {
    padding: 17px 20px;
  }

  .fast_custom .content_tit .sub_style_change a {
    font-size: 16px;
  }

  .fast_custom .content_tit .sub_style_change {
    width: calc(100% - 240px);
  }

  .fast_custom .select_box {
    width: 240px;
  }

  .fast_custom .select_box select {
    font-size: 16px;
  }
}

@media all and (max-width: 767px) {
  .fast_custom .shop_tit {
    height: 280px;
  }

  .fast_custom .shop_tit .txt {
    padding: 30px;
  }

  .fast_custom .shop_tit .txt h2 {
    font-size: 32px;
  }

  .fast_custom .shop_tit .txt p {
    font-size: 16px;
    margin-top: 5px;
  }

  .fast_custom .sub_style_change {
    padding: 21px 20px;
  }

  .fast_custom .sub_style_change a span {
    display: none;
  }
}

.fast_con1 {
  padding: 10px 0 150px;
  font-size: 0;
  letter-spacing: -4px;
}

.fast_con1 .top_box {
  padding-top: 40px;
}

.fast_con1 .top_box:after {
  content: '';
  display: block;
  clear: both;
}

.fast_con1 .top_box .left_box {
  float: left;
}

.fast_con1 .top_box .left_box p {
  font-size: 18px;
  letter-spacing: -0.04em;
  color: #666666;
  font-weight: 300;
  margin-top: 15px;
}

.fast_con1 .top_box .left_box p b {
  font-weight: 500;
  color: #ff7200;
}

.fast_con1 .top_box .left_box p strong {
  font-size: 16px;
  letter-spacing: -0.04em;
  color: #999999;
  font-weight: 300;
}

.fast_con1 .top_box .right_box {
  float: right;
}

.fast_con1 .top_box .right_box .input_box {
  position: relative;
  width: 289px;
}

.fast_con1 .top_box .right_box .input_box input {
  width: 100%;
  padding: 0 10px 10px 30px;
  box-sizing: border-box;
  border: 0;
  border-bottom: 1px solid #ff7200;
  font-size: 18px;
  letter-spacing: -0.04em;
  color: #aaaaaa;
  font-weight: 500;
}

.fast_con1 .top_box .right_box .input_box input:focus {
  outline: none;
}

.fast_con1 .top_box .right_box .input_box input::placeholder {
  font-size: 18px;
  letter-spacing: -0.04em;
  color: #aaaaaa;
  font-weight: 500;
}

.fast_con1 .top_box .right_box .input_box a {
  position: absolute;
  left: 0;
  top: 5px;
}

.fast_con1 .prod_list_box {
  padding-top: 40px;
}

.fast_con1 .prod_list_box ul li {
  display: inline-block;
  vertical-align: top;
  width: calc(50% - 10px);
  margin-right: 20px;
  border: 1px solid #e5e5e5;
  border-radius: 10px;
  box-sizing: border-box;
  padding: 45px 25px;
  cursor: pointer;
  max-height: 210px;
}

.fast_con1 .prod_list_box ul li:nth-of-type(2n) {
  margin-right: 0;
}

.fast_con1 .prod_list_box ul li:nth-of-type(n + 3) {
  margin-top: 20px;
}

.fast_con1 .prod_list_box ul li:hover {
  border: 3px solid #ff7200;
  padding: 43px 23px;
}

.fast_con1 .prod_list_box ul li .img_box {
  display: inline-block;
  vertical-align: top;
  width: 25%;
  text-align: center;
}

.fast_con1 .prod_list_box ul li .img_box img {
  max-width: 100%;
  max-height: 150px;
}

.fast_con1 .prod_list_box ul li .txt_box {
  display: inline-block;
  vertical-align: top;
  width: 75%;
  padding-left: 35px;
  box-sizing: border-box;
}

.fast_con1 .prod_list_box ul li .txt_box .top_box {
  padding-top: 0;
}

.fast_con1 .prod_list_box ul li .txt_box .top_box .left_box {
  display: inline-block;
  vertical-align: middle;
  width: calc(100% - 70px);
}

.fast_con1 .prod_list_box ul li .txt_box .top_box .left_box span {
  width: 70px;
  height: 30px;
  display: inline-block;
  vertical-align: top;
  border-radius: 15px;
  background: #ff7200;
  text-align: center;
  line-height: 30px;
  font-size: 16px;
  letter-spacing: -0.04em;
  color: #ffffff;
  font-weight: 300;
}

.fast_con1 .prod_list_box ul li .txt_box .top_box .left_box span.black {
  background: #0d0d0d;
}

.fast_con1 .prod_list_box ul li .txt_box .top_box .left_box span:not(:first-child) {
  margin-left: 10px;
}

.fast_con1 .prod_list_box ul li .txt_box .top_box .right_box {
  display: inline-block;
  vertical-align: middle;
  width: 70px;
}

.fast_con1 .prod_list_box ul li .txt_box .top_box .right_box a {
  width: 70px;
  height: 30px;
  border-radius: 15px;
  background: #fff;
  text-align: center;
  line-height: 28px;
  font-size: 16px;
  letter-spacing: -0.04em;
  color: #666666;
  font-weight: 300;
  border: 1px solid #666666;
  display: block;
  box-sizing: border-box;
}

.fast_con1 .prod_list_box ul li .txt_box .bot_box {
  padding-top: 20px;
}

.fast_con1 .prod_list_box ul li .txt_box .bot_box p {
  font-size: 24px;
  letter-spacing: -0.04em;
  color: #222222;
  font-weight: bold;
  padding-bottom: 3px;
  word-break: keep-all;
  min-height: 58px;
  display: -webkit-box;
  word-wrap: break-word;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 2.4em;
}

.fast_con1 .prod_list_box ul li .txt_box .bot_box span {
  display: inline-block;
  vertical-align: middle;
  font-size: 18px;
  letter-spacing: -0.04em;
  color: #666666;
  font-weight: 400;
  margin-right: 30px;
  position: relative;
}

.fast_con1 .prod_list_box ul li .txt_box .bot_box span:not(:last-child):after {
  content: '';
  position: absolute;
  right: -14px;
  top: 50%;
  transform: translateY(-50%);
  width: 1px;
  height: 10px;
  background: #bfbfbf;
}

.fast_con1 .prod_list_box ul li .txt_box .bot_box span:last-of-type {
  margin-right: 0;
}

/* 20220818 목록형 리스트 변경 */
.fast_con1 .prod_list_box02 {
  padding-top: 40px;
}

.fast_con1 .prod_list_box02 ul li {
  display: inline-block;
  vertical-align: top;
  width: 100%;
  box-sizing: border-box;
  padding: 45px 25px;
  cursor: pointer;
  height: 198px;
}

.fast_con1 .prod_list_box02 ul li:not(:first-child) {
  margin-top: 20px;
}

.fast_con1 .prod_list_box02 ul li:not(:last-child) {
  border-bottom: 1px solid #eee;
}

.fast_con1 .prod_list_box02 ul li .img_box {
  display: inline-block;
  vertical-align: middle;
  width: 150px;
  text-align: center;
}

.fast_con1 .prod_list_box02 ul li .img_box img {
  max-width: 100%;
  max-height: 115px;
}

.fast_con1 .prod_list_box02 ul li .txt_box {
  display: inline-block;
  vertical-align: middle;
  width: 75%;
  padding-left: 35px;
  box-sizing: border-box;
}

.fast_con1 .prod_list_box02 ul li .txt_box .top_box {
  padding-top: 0;
}

.fast_con1 .prod_list_box02 ul li .txt_box .bot_box {
  padding-top: 20px;
}

.fast_con1 .prod_list_box02 ul li .txt_box .top_box .left_box {
  display: inline-block;
  vertical-align: middle;
  width: calc(100% - 70px);
}

.fast_con1 .prod_list_box02 ul li .txt_box .top_box .left_box span {
  width: 70px;
  height: 30px;
  display: inline-block;
  vertical-align: top;
  border-radius: 15px;
  background: #ff7200;
  text-align: center;
  line-height: 30px;
  font-size: 16px;
  letter-spacing: -0.04em;
  color: #ffffff;
  font-weight: 300;
}

.fast_con1 .prod_list_box02 ul li .txt_box .top_box .left_box span.black {
  background: #0d0d0d;
}

.fast_con1 .prod_list_box02 ul li .txt_box .top_box .left_box span:not(:first-child) {
  margin-left: 10px;
}

.fast_con1 .prod_list_box02 ul li .txt_box .bot_box p {
  font-size: 24px;
  letter-spacing: -0.04em;
  color: #222222;
  font-weight: bold;
  padding-bottom: 15px;
  word-break: keep-all;
}

.fast_con1 .prod_list_box02 ul li .txt_box .bot_box span {
  display: inline-block;
  vertical-align: middle;
  font-size: 18px;
  letter-spacing: -0.04em;
  color: #666666;
  font-weight: 400;
  margin-right: 30px;
  position: relative;
}

.fast_con1 .prod_list_box02 ul li .txt_box .bot_box span:not(:last-child):after {
  content: '';
  position: absolute;
  right: -14px;
  top: 50%;
  transform: translateY(-50%);
  width: 1px;
  height: 10px;
  background: #bfbfbf;
}

@media all and (max-width: 1000px) {
  .fast_con1 .prod_list_box ul li {
    width: 100%;
    margin-right: 0;
  }

  .fast_con1 .prod_list_box ul li:nth-of-type(n + 2) {
    margin-top: 20px;
  }

  .fast_con1 .prod_list_box ul li .txt_box {
    padding-left: 10px;
  }
}

@media all and (max-width: 767px) {
  .fast_con1 .top_box {
    padding-top: 0;
  }
}

.shopList_custom.shopList.fast_read_custom {
  font-size: 0;
  letter-spacing: -4px;
}

.shopList_custom.shopList.fast_read_custom .left {
  display: inline-block;
  vertical-align: top;
  float: none;
  width: calc(50% - 30px);
  margin-right: 60px;
  margin-top: 78px;
}

.shopList_custom.shopList.fast_read_custom .left.purchase {
  margin-top: 80px;
}

.shopList_custom.shopList.fast_read_custom .right {
  display: inline-block;
  vertical-align: middle;
  float: none;
  width: calc(50% - 30px);
  padding: 0;
}

.shopList_custom.shopList.fast_read_custom .right.read_custom .top_box span {
  width: 100px;
  height: 40px;
  display: inline-block;
  vertical-align: top;
  border-radius: 7px;
  background: #ff7200;
  text-align: center;
  line-height: 40px;
  font-size: 16px;
  letter-spacing: -0.04em;
  color: #ffffff;
  font-weight: 300;
}

.shopList_custom.shopList.fast_read_custom .right.read_custom .top_box span.black {
  background: #0d0d0d;
}

.shopList_custom.shopList.fast_read_custom .right.read_custom .top_box span:not(:first-child) {
  margin-left: 10px;
}

.shopList_custom.shopList.fast_read_custom .right.read_custom .title {
  padding-top: 30px;
  border-bottom: 1px solid #ccc;
  padding-bottom: 30px;
  box-sizing: border-box;
}

.shopList_custom.shopList.fast_read_custom .right.read_custom .title p {
  font-size: 28px;
  letter-spacing: -0.04em;
  color: #222;
  font-weight: bold;
  display: inline-block;
  vertical-align: middle;
}

.shopList_custom.shopList.fast_read_custom .right.read_custom .title span {
  width: 70px;
  height: 30px;
  display: inline-block;
  vertical-align: middle;
  border-radius: 15px;
  background: #ff7200;
  text-align: center;
  line-height: 30px;
  font-size: 16px;
  letter-spacing: -0.04em;
  color: #ffffff;
  font-weight: 300;
  margin-right: 15px;
}

.shopList_custom.shopList.fast_read_custom .right.read_custom .dl_box {
  padding-top: 30px;
}

.shopList_custom.shopList.fast_read_custom .right.read_custom .dl_box dl + dl {
  padding-top: 20px;
}

.shopList_custom.shopList.fast_read_custom .right.read_custom .dl_box dl dt {
  display: inline-block;
  vertical-align: middle;
  width: 100px;
  font-size: 18px;
  letter-spacing: -0.04em;
  color: #444;
  font-weight: 500;
}

.shopList_custom.shopList.fast_read_custom .right.read_custom .dl_box dl dd {
  display: inline-block;
  vertical-align: middle;
  width: calc(100% - 100px);
  font-size: 18px;
  letter-spacing: -0.04em;
  color: #666;
  font-weight: 400;
}

.shopList_custom.shopList.fast_read_custom .right.read_custom .info_btn_box {
  text-align: right;
  padding-top: 100px;
}

.shopList_custom.shopList.fast_read_custom .right.read_custom .info_btn_box a {
  display: inline-block;
  vertical-align: top;
  width: 200px;
  height: 50px;
  border: 1px solid #666;
  border-radius: 5px;
  line-height: 48px;
  box-sizing: border-box;
  text-align: center;
  font-size: 20px;
  letter-spacing: -0.04em;
  color: #fff;
  background: #666;
  margin-right: 20px;
}

.shopList_custom.shopList.fast_read_custom .right.read_custom .info_btn_box a.pur_btn {
  background: #ff7200;
  border: 1px solid #ff7200;
}

.shopList_custom.shopList.fast_read_custom .right.read_custom .info_btn_box a.list {
  background: #666;
  border: 1px solid #666;
}

.shopList_custom.shopList.fast_read_custom .right.read_custom .info_btn_box a.phone_num {
  color: #222;
  background: #fff;
  color: #222;
  border: 1px solid #222;
}

.shopList_custom.shopList.fast_read_custom .right.read_custom .info_btn_box a:last-of-type {
  margin-right: 0;
}

.shopList_custom.shopList.fast_read_custom .detail_Con {
  margin-top: 50px;
}

.shopList_custom.shopList.fast_read_custom .detail_Con .detail_Tab ul li {
  width: 100%;
}

.shopList_custom.shopList.fast_read_custom .desc {
  padding-top: 100px;
  font-size: 16px;
  letter-spacing: -0.04em;
}

.contant .fast_down_box {
  position: relative;
  background: #f3f9f9;
  letter-spacing: -4px;
  font-size: 0;
  padding: 40px 40px 0px 40px;
  margin-bottom: 50px;
  margin-top: 50px;
}

@media all and (max-width: 1400px) {
  .shopList_custom.shopList.fast_read_custom .right.read_custom .info_btn_box a {
    margin-right: 10px;
  }
}

@media all and (max-width: 1000px) {
  .shopList_custom.shopList.fast_read_custom {
    padding-top: 0;
  }

  .shopList_custom.shopList.fast_read_custom .right.read_custom .top_box {
    margin-top: 30px;
  }

  .shopList_custom.shopList.fast_read_custom .left {
    width: 100%;
  }

  .shopList_custom.shopList.fast_read_custom .right {
    width: 100%;
  }

  .shopList_custom.shopList.fast_read_custom .right.read_custom .info_btn_box a {
    margin-right: 5px;
  }
}

.contant .fast_down_box:before {
  content: '';
  position: absolute;
  left: 50%;
  top: 16%;
  width: 1px;
  height: 130px;
  background: #ddd;
}

.contant .fast_down_box .left_box {
  display: inline-block;
  vertical-align: top;
  width: 50%;
  box-sizing: border-box;
}

.contant .fast_down_box .left_box dl {
  margin-top: 15px;
}

.contant .fast_down_box .left_box dl dt {
  width: 120px;
  display: inline-block;
  vertical-align: top;
}

.contant .fast_down_box .left_box dl dt p {
  font-size: 18px;
  color: #222;
  font-weight: 500;
  letter-spacing: -0.04em;
}

.contant .fast_down_box .left_box dl dd {
  width: calc(100% - 120px);
  display: inline-block;
  vertical-align: top;
}

.contant .fast_down_box .left_box dl dd p {
  font-size: 16px;
  color: #222;
  font-weight: 300;
  letter-spacing: -0.04em;
}

.contant .fast_down_box .right_box {
  display: inline-block;
  vertical-align: top;
  width: 50%;
  box-sizing: border-box;
  padding: 39.5px;
}

.contant .fast_down_box .right_box dl {
  margin-top: 15px;
}

.contant .fast_down_box .right_box dl dt {
  width: 120px;
  display: inline-block;
  vertical-align: top;
}

.contant .fast_down_box .right_box dl dt p {
  font-size: 18px;
  color: #222;
  font-weight: 500;
  letter-spacing: -0.04em;
}

.contant .fast_down_box .right_box dl dd {
  width: calc(100% - 120px);
  display: inline-block;
  vertical-align: top;
}

.contant .fast_down_box .right_box dl dd p {
  font-size: 16px;
  color: #222;
  font-weight: 300;
  letter-spacing: -0.04em;
}

@media all and (max-width: 1000px) {
  .contant .fast_down_box {
    padding: 40px 0 0 10px;
  }

  .contant .fast_down_box:before {
    display: none;
  }

  .contant .fast_down_box .left_box {
    width: 100%;
  }

  .contant .fast_down_box .right_box {
    padding: 0;
  }
}

/*쇼핑리스트 커스텀*/
.ShopCon_menu_Con .hot_Deal .hot_Deal_slider .swiper-slide .img_box {
  position: relative;
  padding: 50% 0;
  overflow: hidden;
}

.ShopCon_menu_Con .hot_Deal .hot_Deal_slider .swiper-slide .img_box img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  max-width: 100%;
  max-height: 100%;
  margin: auto;
}

/*일빵문의 게시판 버튼 커스텀*/
#useFile a.custom_btns_blue {
  padding: 0 9px;
}

/*글쓰기 버튼 */
.contant.fast_custom .custom_write_btn {
  font-size: 15px;
  color: #fff;
  letter-spacing: -0.04em;
}

/*일빵문의*/
.inquiry .list_btn {
  padding-top: 30px;
}

.inquiry .list_btn ul {
  letter-spacing: -4px;
  font-size: 0;
}

.inquiry .list_btn ul li {
  display: inline-block;
  vertical-align: top;
  margin-right: 10px;
  background: #eff2f4;
  border-radius: 12px;
}

.inquiry .list_btn ul li:nth-of-type(3) {
  margin-right: 0;
}

.inquiry .list_btn ul li a {
  display: inline-block;
  font-size: 18px;
  color: #9ea2ad;
  width: 150px;
  height: 47px;
  line-height: 50px;
  letter-spacing: -0.04em;
  text-align: center;
  font-weight: 500;
  box-sizing: border-box;
}

.inquiry .list_btn ul li.on {
  background: #416ad4;
}

.inquiry .list_btn ul li.on a {
  color: #fff;
}

.inquiry .tit {
  text-align: center;
  font-size: 35px;
  letter-spacing: -0.045em;
  font-weight: 700;
  padding: 50px 30px;
  border: 1px solid #ddd;
}

/*일빵에 제품공급 신청 */
.consult_tit .consult_tit_box {
  text-align: center;
  border: 1px solid #ddd;
  box-sizing: border-box;
  padding: 50px 30px;
  margin-top: 20px;
  margin-bottom: 50px;
}

.consult_tit .consult_tit_box h3 {
  font-size: 40px;
  letter-spacing: -0.04em;
  color: #222;
}

.consult_tit .consult_txt_box {
  padding-left: 150px;
}

.consult_tit .consult_txt_box p {
  font-size: 20px;
  color: #222;
  font-weight: 400;
  letter-spacing: -0.045em;
}

.consult_tit .consult_txt_box p + p {
  margin-top: 15px;
}

.consult_tit .consult_txt_box p b {
  color: #ff7200;
}

.programCon.custom_libbang .custom_write_libbang table tbody tr th {
  text-align: center;
  font-size: 18px;
  color: #222;
  letter-spacing: -0.04em;
  font-weight: 500;
}

.programCon.custom_libbang .custom_write_libbang table tr td .inputTxt {
  width: 50%;
}

.programCon.custom_libbang .custom_write_libbang .tit_box {
  margin-bottom: 20px;
}

.programCon.custom_libbang .custom_write_libbang .tit_box span {
  font-size: 30px;
  color: #222;
  letter-spacing: -0.045em;
  font-weight: 500;
}

/*조합주관공동구매*/
.sub_list_custom .box .text .price {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.sub_list_custom .box .text .right_tit {
  width: calc(100% - 70px);
}

.sub_list2 .box .text .right_tit {
  width: auto;
  margin-right: 10px;
}

.badge_box span {
  display: inline-block;
  width: 70px;
  height: 30px;
  border-radius: 15px;
  background-color: #ff7200;
  text-align: center;
  line-height: 30px;
  font-size: 16px;
  letter-spacing: -0.05em;
  color: #fff;
  font-weight: 300;
}

.badge_box span.end {
  background-color: #666;
}

.badge_box.big span {
  height: 40px;
  width: 100px;
  border-radius: 7px;
  line-height: 40px;
}

/*MRO 뉴스*/
.program_table table tr td p.mw100 {
  font-size: 25px;
  font-weight: 500;
  padding: 25px 0;
}
