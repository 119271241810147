$main: #f17823;
$main_dark: #df5b4b;
$lightGray: #e0e0e0;
$gray: #9d9d9d;
$darkGray: #858585;
$darkBlue: #00356a;
$white: #fefefe;
$dark: #0d0d0d;
$green: #5fbc26;
$section: #f5f5f5;
