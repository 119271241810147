@media screen and (min-width: 1280px) {
}
@media screen and (max-width: 1279px) {
}
@media screen and (max-width: 767px) {
}
/* 상품 리스트 */
.product-list {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 40px 24px;
}
.product-list .product-item {
  width: calc((100% - 72px) / 4);
}
.product-list .product-item:hover {
  cursor: pointer;
}
.product-list .product-item .thumbnail {
  width: 100%;
  padding-bottom: 56.25%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  box-sizing: border-box;
  border: 1px solid #c9c9c9;
  border-radius: 8px;
  display: flex;
  position: relative;
}
.product-list .product-item .thumbnail.sample::before {
  content: '썸네일 이미지';
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.product-list .product-item .info {
  width: 100%;
  padding-top: 16px;
  font-size: 16px;
}
.product-list .product-item .info .info-name {
  width: 100%;
  font-size: 16px;
  line-height: 1.5;
  /* overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis; */
  word-break: break-all;
  margin-top: 8px;
}
.product-list .product-item .info .info-con {
  font-size: 16px;
  line-height: 20px;
  margin-top: 8px;
  color: #444;
  overflow: hidden;
  white-space: normal;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  word-break: keep-all;
}
.product-list .product-item .info .info-price {
  font-size: 18px;
  line-height: 28px;
  font-family: 'Pretendard-bold';
}
.product-list .product-item .info .info-making {
  font-size: 16px;
  line-height: 20px;
  margin-top: 8px;
}
@media screen and (max-width: 1279px) {
  .product-list .product-item {
    width: calc((100% - 48px) / 3);
  }
}
@media screen and (max-width: 767px) {
  .product-list {
    gap: 24px;
  }
  .product-list .product-item {
    width: 100%;
  }
  .product-list .product-item .info .info-name {
    font-size: 14px;
    line-height: 1.5;
  }
  .product-list .product-item .info .info-con {
    font-size: 14px;
    line-height: 20px;
  }
  .product-list .product-item .info .info-price {
    font-size: 16px;
    line-height: 28px;
  }
  .product-list .product-item .info .info-making {
    font-size: 14px;
    line-height: 20px;
  }
}

/* 상세페이지 */
.product-detail {
  width: 100%;
}
.detail-head {
  padding-bottom: 80px;
  display: flex;
}
.detail-head .thumbnail-box {
  width: 500px;
}
.detail-head .thumbnail-box .thumbnail {
  width: 100%;
  height: 500px;
  /* background-color: red; */
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
}

.detail-head .thumbnail-box .thumbnail-list {
  width: 100%;
  margin-top: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 24px;
}
.detail-head .thumbnail-box .thumbnail-list .thumbnail-item {
  width: 92px;
  height: 92px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  box-sizing: border-box;
  border: 1px solid #ddd;
  cursor: pointer;
}
.detail-head .thumbnail-box .thumbnail-list .thumbnail-item.active {
  border-color: #000;
}
.detail-head .detail-box {
  width: calc(100% - 500px);
  box-sizing: border-box;
  padding-left: 48px;
}
.detail-head .detail-box .top {
  display: flex;
  align-items: center;
}
.detail-head .detail-box .top .title {
  padding-right: 20px;
  flex: 12;
  font-size: 24px;
  line-height: 34px;
  font-family: 'Pretendard-bold';
}
.detail-head .detail-box .top .share {
  flex: 1;
  width: 48px;
  height: 48px;
  box-sizing: border-box;
  border: 1px solid #bdbdbd;
  border-radius: 100%;
  background: url('../img/icon/icon-share.svg') no-repeat center;
  background-size: 24px;
  margin-left: auto;
  cursor: pointer;
}
.detail-head .detail-box .bot {
  margin-top: 48px;
}
.detail-head .detail-box .bot .price-box {
  width: 100%;
  padding-bottom: 24px;
  border-bottom: 1px solid #eee;
  display: flex;
  align-items: flex-end;
}

.detail-head .detail-box .bot .price-box .price {
  font-size: 24px;
  line-height: 34px;
  font-family: 'Pretendard-bold';
}
.detail-head .detail-box .bot .price-box .price-info {
  font-size: 18px;
  line-height: 28px;
  color: #888;
  margin-left: 24px;
}
.detail-head .detail-box .bot .info {
  width: 100%;
  padding: 24px 0;
}
.detail-head .detail-box .bot .info .info-item {
  margin-top: 20px;
  display: flex;
}
.detail-head .detail-box .bot .info .info-item:first-child {
  margin-top: 0;
}
.detail-head .detail-box .bot .info .info-item .title {
  font-size: 16px;
  line-height: 24px;
  width: 100px;
  box-sizing: border-box;
  padding-right: 8px;
  font-family: 'Pretendard-semibold';
}
.detail-head .detail-box .bot .info .info-item .con {
  font-size: 16px;
  line-height: 24px;
  font-family: 'Pretendard-regular';
}
.detail-head .detail-box .bot .buy-box {
  width: 100%;
  box-sizing: border-box;
  padding: 24px;
  background-color: #f8f8f8;
  margin-top: 80px;
}
.detail-head .detail-box .bot .buy-box .title {
  font-size: 16px;
  margin-bottom: 24px;
}
.detail-head .detail-box .bot .buy-box .con {
  display: flex;
  align-items: center;
}
.detail-head .detail-box .bot .buy-box .con .num {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  border: 1px solid #ddd;
}
.detail-head .detail-box .bot .buy-box .con .num button {
  width: 32px;
  height: 32px;
  background-color: #fff;
  background-size: 24px;
  background-repeat: no-repeat;
  background-position: center;
}
.detail-head .detail-box .bot .buy-box .con .num button.num-minus {
  background-image: url('../img/icon/icon-remove.svg');
}
.detail-head .detail-box .bot .buy-box .con .num button.num-plus {
  background-image: url('../img/icon/icon-add.svg');
}
.detail-head .detail-box .bot .buy-box .con .num .num-input {
  border: 1px solid #ddd;
  border-top: none;
  border-bottom: none;
  box-sizing: border-box;
  width: 80px;
  height: 32px;
  text-align: center;
}
.num-input {
  padding: 0 10px;
}

.detail-head .detail-box .bot .buy-box .con .num .num-input::-webkit-outer-spin-button,
.detail-head .detail-box .bot .buy-box .con .num .num-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.detail-head .detail-box .bot .buy-box .con .total {
  margin-left: auto;
}
.detail-head .detail-box .bot .buy-box .con .total .price {
  font-size: 20px;
}
.detail-head .detail-box .bot .buy-box .con .total .won {
  font-size: 14px;
  margin-left: 4px;
}
.detail-head .detail-box .bot .total-price {
  margin-top: 60px;
  display: flex;
  align-items: center;
}
.detail-head .detail-box .bot .total-price .title {
  font-size: 24px;
}
.detail-head .detail-box .bot .total-price .price {
  margin-left: auto;
}
.detail-head .detail-box .bot .total-price .price .num {
  font-size: 36px;
  color: #c32922;
}
.detail-head .detail-box .bot .total-price .price .won {
  font-size: 24px;
  margin-left: 4px;
}

.detail-head .detail-box .bot .btn-list {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 24px;
}
.detail-head .detail-box .bot .btn-list .product-like {
  width: 60px;
  height: 60px;
  box-sizing: border-box;
  border: 1px solid #ddd;
  background: url('../img/icon/icon-like.svg') no-repeat center;
  background-size: 24px;
}
.detail-head .detail-box .bot .btn-list .product-buy {
  width: calc((100%) / 2);
  height: 60px;
  box-sizing: border-box;
  border: 1px solid #ddd;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.detail-head .detail-box .bot .btn-list .product-cart {
  width: calc((100%) / 2);
  height: 60px;
  box-sizing: border-box;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: #2e4995;
  color: #fff;
}

.detail-body {
  width: 100%;
  position: relative;
  padding-top: 8px;
  margin-top: 80px;
}
.detail-body::before {
  content: '';
  width: 100vw;
  height: 8px;
  background-color: #ddd;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
}
.detail-body .detail-menu {
  width: 100%;
  height: 52px;
  display: flex;
  align-items: center;
  position: sticky;
  top: 0;
}
.detail-body .detail-menu::before {
  content: '';
  width: 100vw;
  height: 100%;
  z-index: -1;
  box-sizing: border-box;
  border-bottom: 1px solid #f8f8f8;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0);
  background-color: #fff;
}
.detail-body .detail-menu .menu-item {
  flex: 1 1 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  color: #888;
}
.detail-body .detail-menu .menu-item.current {
  color: #2e4995;
  position: relative;
}
.detail-body .detail-menu .menu-item.current::before {
  content: '';
  width: 100%;
  height: 2px;
  background-color: #2e4995;
  position: absolute;
  bottom: 0;
  left: 0;
}
.detail-body .detail-con {
  padding: 40px;
}
.detail-body .detail-con .item {
  margin-top: 80px;
}
.detail-body .detail-con .item:first-child {
  margin-top: 0;
}
.detail-body .detail-con .item.product-info img {
  width: 100%;
}
.detail-body .detail-con .item.product-info img {
  width: 100%;
}

.detail-body .detail-con .item .item-head {
  width: 100%;
  margin-bottom: 80px;
  border-bottom: 1px solid black;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.detail-body .detail-con .item .item-head .title {
  font-size: 32px;
}

/* 공통 체크 박스 */
.chk-box {
  display: flex;
  width: 100%;
  height: 52px;
  align-items: center;
  box-sizing: border-box;
  padding: 0 20px;
}
.chk-box input[type='checkbox'] {
  display: none;
}
.chk-box input[type='checkbox'] + label {
  display: inline-block;
  width: 28px;
  height: 28px;
  border: 1px solid #e0e0e0;
  position: relative;
}
.chk-box .chk-box__icon {
  width: 20px;
  height: 20px;
  border-radius: 6px;
  box-sizing: border-box;
  border: 1px solid #e0e0e0;
  position: relative;
}
.chk-box input:checked ~ .chk-box__icon {
  background-color: #ff7200;
  border: 0;
}
.chk-box input:checked ~ .chk-box__icon::before {
  content: '';
  background: url('../img/icon/icon-chk.svg') no-repeat;
  background-size: cover;
  position: absolute;
  width: 20px;
  height: 20px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  z-index: 1;
}
.chk-box .chk-box__txt {
  font-size: 16px;
  margin-left: 8px;
}

/* 공통 인풋 박스 */
input {
  width: 100%;
  height: 48px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  box-sizing: border-box;
  padding: 0 20px;
}
input:focus,
input:focus-visible,
input:active {
  outline: none;
}
input:disabled {
  border: none;
  background-color: #f5f5f5;
}

/* 서브페이지 해더 */
.con-head {
  width: 100%;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
}
.con-head .con-title {
  font-size: 34px;
  font-family: 'Pretendard-bold';
  margin: 0 auto;
}
.con-head-sub {
  width: 100%;
  margin-bottom: 52px;
  display: flex;
  align-items: center;
}
.con-head-sub .con-title-sub {
  font-size: 20px;
  font-family: 'Pretendard-bold';
  margin: 0 auto;
}

/* 회원가입 시작 */
.join {
  width: 600px;
  margin: 0 auto;
}
.join .terms-box {
  width: 100%;
}
.join .terms-box .terms-item {
  width: 100%;
  margin-top: 32px;
  border: 1px solid #e0e0e0;
  border-radius: 16px;
}
.join .terms-box .terms-item:first-child {
  margin-top: 0;
}
.join .terms-box .terms-item .terms-head {
  width: 100%;
  border-bottom: 1px solid #e0e0e0;
}
.join .terms-box .terms-item .terms-body {
  width: 100%;
  height: 200px;
  padding: 20px;
  box-sizing: border-box;
  overflow-y: auto;
}

/* 인풋 리스트 */
.input-list {
  width: 100%;
}
.input-list .input-item {
  width: 100%;
  margin-top: 24px;
}
.input-list .input-item:first-child {
  margin-top: 0;
}
.input-list .input-item .input-head {
  margin-bottom: 12px;
}
.input-list .input-item .input-head .title {
  font-size: 14px;
}
.input-list .input-item .input-head .required {
  color: rgb(238, 106, 123);
}
.input-list .input-item .input-body.address {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}
.input-list .input-item .input-body.address .first-address {
  width: calc(100% - 148px);
}
.input-list .input-item .input-body.input-button-div {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}
.input-list .input-item .input-body.input-button-div .input-type {
  width: calc(100% - 148px);
}
.input-btn {
  width: 140px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #424242;
  color: #424242;
  border-radius: 6px;
  font-size: 16px;
}
.input-list .input-item .input-body.address .second-address {
  width: 100%;
}
.input-list .input-item .input-body.address .second-address {
  width: 100%;
}
.input-list .input-item .input-body.type_02 {
  position: relative;
  display: flex;
  align-items: center;
  gap: 8px;
}
.input-list .input-item .input-body.type_02 .input-box {
  width: calc(100% - 148px);
}

/* 페이지 버튼 리스트 */
.page-btn {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 16px;
  margin-top: 42px;
}
.page-btn .page-btn-item {
  flex: 1 1 0;
  height: 52px;
  background-color: #f5f5f5;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  color: #bdbdbd;
  border-radius: 6px;
  cursor: pointer;
}
.page-btn .page-btn-item.next {
  background-color: #ff7200;
  color: #fff;
}

/* 서브페이지 탭 */
.page-tab {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 8px;
}
.page-tab .page-tab-item {
  padding: 0 24px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 16px;
  color: #9ea2ad;
  background-color: #eff2f4;
  border-radius: 8px;
}
.page-tab .page-tab-item.current {
  background-color: #ff7200;
  color: #fff;
}

/* 기본 컨텐츠 */
.con-list {
  width: 100%;
  margin-top: 40px;
}
.con-list .con-item {
  width: 100%;
  margin-top: 40px;
}
.con-list .con-item:first-child {
  margin-top: 0;
}
.con-list .con-item .item-head {
  width: 100%;
  margin-bottom: 32px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.con-list .con-item .item-head .title {
  font-size: 18px;
  font-family: 'Pretendard-bold';
}
.con-list .con-item .item-body {
  width: 100%;
}

/* 주문내역 */
.breakdown-list {
  width: 100%;
  border-top: 2px solid #424242;
}
.breakdown-list .breakdown-head {
  display: flex;
  align-items: center;
  width: 100%;
  height: fit-content;
  border-bottom: 1px solid #e0e0e0;
  background-color: #f5f5f5;
}
.breakdown-list .breakdown-head .title {
  text-align: center;
  padding: 30px 0;
}
.breakdown-list .title.info {
  flex-grow: 1;
}
.breakdown-list .breakdown-body .breakdown-item {
  width: 100%;
  box-sizing: border-box;
  padding: 20px 0;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #e0e0e0;
}
.breakdown-list .breakdown-body .breakdown-item .info {
  display: flex;
  align-items: center;
  margin-left: 20px;
  flex-grow: 1;
}
.breakdown-list .breakdown-body .breakdown-item .info .thumbnail {
  width: 140px;
  height: 100px;
  background-color: #ffffff;
  border-radius: 6px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}
.breakdown-list .breakdown-body .breakdown-item .info .thumbnail:hover {
  opacity: 0.5;
}
.breakdown-list .breakdown-body .breakdown-item .info .info-box {
  width: calc(100% - 140px);
  box-sizing: border-box;
  padding-left: 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.breakdown-list .breakdown-body .breakdown-item .info .info-box .p-company {
  font-size: 16px;
  margin-bottom: 8px;
}
.breakdown-list .breakdown-body .breakdown-item .info .info-box .p-name {
  font-size: 16px;
  font-family: 'Pretendard-bold';
}
.breakdown-list .breakdown-body .breakdown-item .date,
.breakdown-list .breakdown-body .breakdown-item .num {
  font-size: 16px;
  text-align: center;
}
.breakdown-list .breakdown-body .breakdown-item .price {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.breakdown-list .breakdown-body .breakdown-item .price .p-price,
.breakdown-list .breakdown-body .breakdown-item .price .p-num {
  font-size: 16px;
}
.breakdown-list .breakdown-body .breakdown-item .price .p-num {
  color: #777777;
  margin-top: 8px;
}
.breakdown-list .breakdown-body .breakdown-item .status {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  flex-direction: column;
}
.breakdown-list .breakdown-body .breakdown-item .status button {
  width: 140px;
  height: 40px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 16px;
  border: 1px solid #000000;
  border-radius: 6px;
}
.breakdown-list .breakdown-body .breakdown-item .status .cancel-btn {
  color: #c32922;
  border-color: #c32922;
  cursor: pointer;
}
.breakdown-list .breakdown-body .breakdown-item .status .confirm-btn {
  color: #2e4995;
  border-color: #2e4995;
  cursor: pointer;
}

.breakdown-list .breakdown-body .breakdown-item.hover-action:hover {
  cursor: pointer;
  background-color: #f5f5f5;
}

.breakdown-list .info {
  width: 38%;
}
.breakdown-list .date {
  width: 14%;
}
.breakdown-list .num {
  width: 14%;
}
.breakdown-list .price {
  width: 14%;
}
.breakdown-list .status {
  width: 16%;
}

input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.cart-list {
  position: relative;
  width: 1000px;
}
.cart-list .cart-item {
  width: 90%;
  box-sizing: border-box;
  border: 1px solid #e0e0e0;
  border-radius: 6px;
  padding: 20px;
  margin-top: 24px;
  display: flex;
  align-items: center;
  position: relative;
}
.cart-list .cart-item:first-child {
  margin-top: 0;
}

.cart-list .cart-item div .thumbnail {
  width: 200px;
  height: 120px;
  border-radius: 6px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}
.cart-list .cart-item .cart-info {
  width: calc(100% - 240px);
  margin-left: auto;
  position: relative;
}
.cart-list .cart-item .delete-btn {
  width: 32px;
  height: 32px;
  background: url('../img/icon/icon-close.svg') no-repeat;
  background-size: cover;
  position: absolute;
  right: 20px;
  top: 20px;
}
.cart-list .cart-item .cart-info .p-company {
  font-size: 16px;
  margin-bottom: 8px;
}
.cart-list .cart-item .cart-info .p-name {
  font-size: 18px;
  font-family: 'Pretendard-bold';
  margin-bottom: 16px;
}
.cart-list .cart-item .cart-info .p-num {
  position: absolute;
  right: 0;
  bottom: 0;
}
.cart-list .cart-item .cart-info .p-price {
  font-size: 20px;
  font-family: 'Pretendard-bold';
}
.cart-info .num-box {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  border: 1px solid #ddd;
  width: 126px;
}
.cart-info .num-box button {
  width: 32px;
  height: 32px;
  background-color: #fff;
  background-size: 24px;
  background-repeat: no-repeat;
  background-position: center;
}
.cart-info .num-box button.num-minus {
  background-image: url('../img/icon/icon-remove.svg');
}
.cart-info .num-box button.num-plus {
  background-image: url('../img/icon/icon-add.svg');
}
.cart-info .num-box .num-input {
  border: 1px solid #ddd;
  border-top: none;
  border-bottom: none;
  box-sizing: border-box;
  width: 60px;
  height: 32px;
  text-align: center;
  border-radius: 0;
}

.pagination {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 32px;
}
.pagination .page-box {
  display: flex;
  align-items: center;
  gap: 8px;
}
.pagination .page-box .page-btn {
  width: 32px;
  height: 32px;
  background-size: 28px;
  background-position: center center;
  margin: 0;
}
.pagination .page-box a {
  cursor: pointer;
}
.pagination .page-box .page-btn.page-first {
  background-image: url('../img/icon/icon-first.svg');
}
.pagination .page-box .page-btn.page-prev {
  background-image: url('../img/icon/icon-prev.svg');
}
.pagination .page-box .page-btn.page-next {
  background-image: url('../img/icon/icon-next_page.svg');
}
.pagination .page-box .page-btn.page-last {
  background-image: url('../img/icon/icon-last.svg');
}
.pagination .page-box .page-num {
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  color: #666666;
}
.pagination .page-box .page-num.current:before {
  content: '';
  width: 100%;
  height: 2px;
  background-color: #2e4995;
  position: absolute;
  left: 0;
  bottom: 0;
}
.pagination .page-box .page-num.current {
  color: #2e4995;
}

/* 상품 등록 */
select {
  width: 100%;
  height: 48px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  box-sizing: border-box;
  padding: 0 20px;
  background: url('../img/icon/icon-down.svg') calc(100% - 20px) center no-repeat;
  background-size: 20px;
  -o-appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
select::-ms-expand {
  display: none;
}
select:focus-visible,
select:focus,
select:active {
  outline: none;
}
textarea {
  width: 100%;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  box-sizing: border-box;
  padding: 20px;
}
.product-add {
  width: 800px;
  margin: 0 auto;
}
.flex-box {
  display: flex;
  align-items: center;
}
.radio-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
  height: 50px;
}
.radio-box input {
  display: none;
}
.radio-box input:checked ~ .radio-box__icon {
  border-color: #2e4995;
}
.radio-box__icon {
  display: inline-block;
  vertical-align: middle;
  width: 24px;
  height: 24px;
  min-width: 24px;
  min-height: 24px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border: 2px solid #eee;
  border-radius: 50%;
  background-color: #fff;
  position: relative;
  cursor: pointer;
}
.radio-box input:checked ~ .radio-box__icon::before,
.radio-box input:checked ~ .radio-box__icon::after {
  content: '';
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 16px;
  height: 16px;
  background-color: #2e4995;
  border-radius: 50%;
}
.radio-box input:checked ~ .radio-box__txt {
  color: #000;
}
.radio-box__txt {
  display: inline-block;
  vertical-align: middle;
  padding-left: 8px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 14px;
}
.radio-list {
  width: 100%;
  display: flex;
  align-items: center;
}
.radio-list label {
  width: 50%;
}

.none-content {
  width: 100%;
  height: 400px;
  border: none;
}
.none-content .none-box {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.none-content .none-box img {
  width: 48px;
  height: 48px;
}
.none-content .none-box .title {
  font-size: 32px;
  font-family: 'Pretendard-bold';
  margin-top: 24px;
}

.content-head {
  width: 100%;
  display: flex;
  align-items: center;
  padding-bottom: 48px;
}
.content-head .btn-list {
  display: inline-flex;
  align-items: center;
  margin-left: auto;
  justify-content: flex-end;
}
.content-head .btn-list .btn-item {
  width: 140px;
  height: 48px;
  background-color: #ff7200;
  color: #fff;
  border-radius: 6px;
  font-size: 16px;
  cursor: pointer;
}

.add-product-btn {
  width: 140px;
  height: 48px;
  background-color: #ff7200;
  color: #fff;
  border-radius: 6px;
  font-size: 16px;
  cursor: pointer;
  margin-top: 50px;
}

.detail-body .detail-option {
  width: 300px;
  position: sticky;
  top: 20px;
  box-sizing: border-box;
  padding: 30px;
  border: 1px solid #e0e0e0;
  background-color: white;
  z-index: 10;
  border-radius: 20px;
}
.detail-body .detail-option .option-wrap {
}
.detail-body .detail-option .option-wrap .product-info-box {
  width: 100%;
  background-color: #f8f7f6;
  padding: 24px;
  box-sizing: border-box;
  border-radius: 20px;
}
.detail-body .detail-option .option-wrap .product-info-box .title {
  font-size: 24px;
  font-family: 'Pretendard-bold';
  word-break: break-all;
  margin-bottom: 24px;
  text-align: center;
}
.detail-body .detail-option .option-wrap .product-info-box .price {
  font-size: 18px;
  margin-top: 24px;
  text-align: center;
}
.detail-body .detail-option .option-wrap .product-info-box .price span {
  font-size: 24px;
  font-family: 'Pretendard-bold';
}
.detail-body .detail-option .option-wrap .product-total {
  margin-top: 100px;
  border-top: 1px solid #e0e0e0;
  box-sizing: border-box;
  padding: 42px 0;
}
.detail-body .detail-option .option-wrap .product-total .title {
  font-size: 24px;
  font-family: 'Pretendard-bold';
  margin-bottom: 24px;
}
.detail-body .detail-option .option-wrap .product-total .price {
  font-size: 18px;
}
.detail-body .detail-option .option-wrap .product-total .price span {
  font-size: 34px;
  color: #c32922;
  font-family: 'Pretendard-bold';
}
.detail-body .detail-option .option-wrap .product-btn {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 24px;
}
.detail-body .detail-option .option-wrap .product-btn button {
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-sizing: border-box;
  border: 1px solid #ddd;
  font-size: 20px;
}
.detail-body .detail-option .option-wrap .product-btn button.product-cart {
  border: none;
  background-color: #2e4995;
  color: #fff;
}

.detail-option .option-wrap .product-info-box .num-box .num {
  position: relative;
  margin: auto;
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  border: 1px solid #ddd;
}
.detail-option .option-wrap .product-info-box .num-box .num button {
  width: 32px;
  height: 32px;
  background-color: #fff;
  background-size: 24px;
  background-repeat: no-repeat;
  background-position: center;
}
.detail-option .option-wrap .product-info-box .num-box .num button.num-minus {
  background-image: url('../img/icon/icon-remove.svg');
}
.detail-option .option-wrap .product-info-box .num-box .num button.num-plus {
  background-image: url('../img/icon/icon-add.svg');
}
.detail-option .option-wrap .product-info-box .num-box .num .num-input {
  border: 1px solid #ddd;
  border-top: none;
  border-bottom: none;
  box-sizing: border-box;
  width: 80px;
  height: 32px;
  text-align: center;
}
.num-input {
  padding: 0 10px;
}

.detail-option .option-wrap .product-info-box .num-box .num .num-input::-webkit-outer-spin-button,
.detail-option .option-wrap .product-info-box .num-box .num .num-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

#checkedTotal {
  :active {
    opacity: 0.4;
  }
}

.shippingCodeBtn {
  cursor: pointer;
  padding: 5px;
  width: 100px;
  border: 0.5px solid black;
  background-color: #ededed;
  font-weight: 200;
  font-size: 15px;
  border-radius: 20px;
}

.shippingCodeBtn:hover {
  opacity: 0.5;
}

.shippingCodeBtn:active {
  opacity: 0.2;
}

.shippingCodeBox {
  padding: 5px;
  width: 100px;
  font-weight: 200;
  font-size: 15px;
  color: #777777;
}
.shippingCodeBox p:first-child {
  color: black;
  margin-bottom: 10px;
}
.shippingCodeBox:hover {
  cursor: pointer;
  text-decoration: underline;
}
.shippingCodeBox:active {
  opacity: 0.5;
}

.product-edit-btn:hover {
  cursor: pointer;
  background-color: #f3f3f3df;
}

.shippingCodeBox-none {
  padding: 5px;
  width: 100px;
  font-weight: 200;
  font-size: 15px;
  color: #777777;
}
.shippingCodeBox-none p:first-child {
  color: black;
  margin-bottom: 10px;
}

.product-list-grid {
  position: relative;
  display: grid;
  width: 100%;
  grid-template-columns: repeat(4, 1fr);
  gap: 50px 30px;

  @media screen and (max-width: 939px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media screen and (max-width: 809px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media screen and (max-width: 639px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media screen and (max-width: 575px) {
    grid-template-columns: repeat(1, 1fr);
  }
}
